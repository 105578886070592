// source: cosmos/gov/v1beta1/gov.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var cosmos_base_v1beta1_coin_pb = require('../../../cosmos/base/v1beta1/coin_pb.js');
goog.object.extend(proto, cosmos_base_v1beta1_coin_pb);
var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var cosmos_proto_cosmos_pb = require('../../../cosmos_proto/cosmos_pb.js');
goog.object.extend(proto, cosmos_proto_cosmos_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
goog.exportSymbol('proto.cosmos.gov.v1beta1.Deposit', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.DepositParams', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.Proposal', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.ProposalStatus', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.TallyParams', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.TallyResult', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.TextProposal', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.Vote', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.VoteOption', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.VotingParams', null, global);
goog.exportSymbol('proto.cosmos.gov.v1beta1.WeightedVoteOption', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.WeightedVoteOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.WeightedVoteOption.displayName = 'proto.cosmos.gov.v1beta1.WeightedVoteOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.TextProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.TextProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.TextProposal.displayName = 'proto.cosmos.gov.v1beta1.TextProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.Deposit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.gov.v1beta1.Deposit.repeatedFields_, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.Deposit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.Deposit.displayName = 'proto.cosmos.gov.v1beta1.Deposit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.Proposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.gov.v1beta1.Proposal.repeatedFields_, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.Proposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.Proposal.displayName = 'proto.cosmos.gov.v1beta1.Proposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.TallyResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.TallyResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.TallyResult.displayName = 'proto.cosmos.gov.v1beta1.TallyResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.Vote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.gov.v1beta1.Vote.repeatedFields_, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.Vote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.Vote.displayName = 'proto.cosmos.gov.v1beta1.Vote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.DepositParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.gov.v1beta1.DepositParams.repeatedFields_, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.DepositParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.DepositParams.displayName = 'proto.cosmos.gov.v1beta1.DepositParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.VotingParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.VotingParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.VotingParams.displayName = 'proto.cosmos.gov.v1beta1.VotingParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.gov.v1beta1.TallyParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.gov.v1beta1.TallyParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.gov.v1beta1.TallyParams.displayName = 'proto.cosmos.gov.v1beta1.TallyParams';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.WeightedVoteOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.WeightedVoteOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    option: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.WeightedVoteOption}
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.WeightedVoteOption;
  return proto.cosmos.gov.v1beta1.WeightedVoteOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.WeightedVoteOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.WeightedVoteOption}
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cosmos.gov.v1beta1.VoteOption} */ (reader.readEnum());
      msg.setOption(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.WeightedVoteOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.WeightedVoteOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOption();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional VoteOption option = 1;
 * @return {!proto.cosmos.gov.v1beta1.VoteOption}
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.prototype.getOption = function() {
  return /** @type {!proto.cosmos.gov.v1beta1.VoteOption} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cosmos.gov.v1beta1.VoteOption} value
 * @return {!proto.cosmos.gov.v1beta1.WeightedVoteOption} returns this
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.prototype.setOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string weight = 2;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.WeightedVoteOption} returns this
 */
proto.cosmos.gov.v1beta1.WeightedVoteOption.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.TextProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.TextProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.TextProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.TextProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.TextProposal}
 */
proto.cosmos.gov.v1beta1.TextProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.TextProposal;
  return proto.cosmos.gov.v1beta1.TextProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.TextProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.TextProposal}
 */
proto.cosmos.gov.v1beta1.TextProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.TextProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.TextProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.TextProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.TextProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TextProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.TextProposal} returns this
 */
proto.cosmos.gov.v1beta1.TextProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TextProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.TextProposal} returns this
 */
proto.cosmos.gov.v1beta1.TextProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.gov.v1beta1.Deposit.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.Deposit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.Deposit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.Deposit.toObject = function(includeInstance, msg) {
  var f, obj = {
    proposalId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    depositor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amountList: jspb.Message.toObjectList(msg.getAmountList(),
    cosmos_base_v1beta1_coin_pb.Coin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.Deposit}
 */
proto.cosmos.gov.v1beta1.Deposit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.Deposit;
  return proto.cosmos.gov.v1beta1.Deposit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.Deposit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.Deposit}
 */
proto.cosmos.gov.v1beta1.Deposit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProposalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepositor(value);
      break;
    case 3:
      var value = new cosmos_base_v1beta1_coin_pb.Coin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.Coin.deserializeBinaryFromReader);
      msg.addAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.Deposit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.Deposit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.Deposit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProposalId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDepositor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      cosmos_base_v1beta1_coin_pb.Coin.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 proposal_id = 1;
 * @return {number}
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.getProposalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.gov.v1beta1.Deposit} returns this
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.setProposalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string depositor = 2;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.getDepositor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.Deposit} returns this
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.setDepositor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated cosmos.base.v1beta1.Coin amount = 3;
 * @return {!Array<!proto.cosmos.base.v1beta1.Coin>}
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.getAmountList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.Coin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.Coin, 3));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.Coin>} value
 * @return {!proto.cosmos.gov.v1beta1.Deposit} returns this
*/
proto.cosmos.gov.v1beta1.Deposit.prototype.setAmountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.Coin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.Coin}
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.addAmount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cosmos.base.v1beta1.Coin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.gov.v1beta1.Deposit} returns this
 */
proto.cosmos.gov.v1beta1.Deposit.prototype.clearAmountList = function() {
  return this.setAmountList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.gov.v1beta1.Proposal.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.Proposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.Proposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.Proposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    proposalId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    content: (f = msg.getContent()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    finalTallyResult: (f = msg.getFinalTallyResult()) && proto.cosmos.gov.v1beta1.TallyResult.toObject(includeInstance, f),
    submitTime: (f = msg.getSubmitTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    depositEndTime: (f = msg.getDepositEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    totalDepositList: jspb.Message.toObjectList(msg.getTotalDepositList(),
    cosmos_base_v1beta1_coin_pb.Coin.toObject, includeInstance),
    votingStartTime: (f = msg.getVotingStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    votingEndTime: (f = msg.getVotingEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.Proposal}
 */
proto.cosmos.gov.v1beta1.Proposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.Proposal;
  return proto.cosmos.gov.v1beta1.Proposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.Proposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.Proposal}
 */
proto.cosmos.gov.v1beta1.Proposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProposalId(value);
      break;
    case 2:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {!proto.cosmos.gov.v1beta1.ProposalStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.cosmos.gov.v1beta1.TallyResult;
      reader.readMessage(value,proto.cosmos.gov.v1beta1.TallyResult.deserializeBinaryFromReader);
      msg.setFinalTallyResult(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSubmitTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDepositEndTime(value);
      break;
    case 7:
      var value = new cosmos_base_v1beta1_coin_pb.Coin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.Coin.deserializeBinaryFromReader);
      msg.addTotalDeposit(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setVotingStartTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setVotingEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.Proposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.Proposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.Proposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProposalId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFinalTallyResult();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cosmos.gov.v1beta1.TallyResult.serializeBinaryToWriter
    );
  }
  f = message.getSubmitTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDepositEndTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTotalDepositList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      cosmos_base_v1beta1_coin_pb.Coin.serializeBinaryToWriter
    );
  }
  f = message.getVotingStartTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVotingEndTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 proposal_id = 1;
 * @return {number}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getProposalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.setProposalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Any content = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getContent = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
*/
proto.cosmos.gov.v1beta1.Proposal.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProposalStatus status = 3;
 * @return {!proto.cosmos.gov.v1beta1.ProposalStatus}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getStatus = function() {
  return /** @type {!proto.cosmos.gov.v1beta1.ProposalStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cosmos.gov.v1beta1.ProposalStatus} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional TallyResult final_tally_result = 4;
 * @return {?proto.cosmos.gov.v1beta1.TallyResult}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getFinalTallyResult = function() {
  return /** @type{?proto.cosmos.gov.v1beta1.TallyResult} */ (
    jspb.Message.getWrapperField(this, proto.cosmos.gov.v1beta1.TallyResult, 4));
};


/**
 * @param {?proto.cosmos.gov.v1beta1.TallyResult|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
*/
proto.cosmos.gov.v1beta1.Proposal.prototype.setFinalTallyResult = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.clearFinalTallyResult = function() {
  return this.setFinalTallyResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.hasFinalTallyResult = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp submit_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getSubmitTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
*/
proto.cosmos.gov.v1beta1.Proposal.prototype.setSubmitTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.clearSubmitTime = function() {
  return this.setSubmitTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.hasSubmitTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp deposit_end_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getDepositEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
*/
proto.cosmos.gov.v1beta1.Proposal.prototype.setDepositEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.clearDepositEndTime = function() {
  return this.setDepositEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.hasDepositEndTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated cosmos.base.v1beta1.Coin total_deposit = 7;
 * @return {!Array<!proto.cosmos.base.v1beta1.Coin>}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getTotalDepositList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.Coin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.Coin, 7));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.Coin>} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
*/
proto.cosmos.gov.v1beta1.Proposal.prototype.setTotalDepositList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.Coin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.Coin}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.addTotalDeposit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.cosmos.base.v1beta1.Coin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.clearTotalDepositList = function() {
  return this.setTotalDepositList([]);
};


/**
 * optional google.protobuf.Timestamp voting_start_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getVotingStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
*/
proto.cosmos.gov.v1beta1.Proposal.prototype.setVotingStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.clearVotingStartTime = function() {
  return this.setVotingStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.hasVotingStartTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp voting_end_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.getVotingEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
*/
proto.cosmos.gov.v1beta1.Proposal.prototype.setVotingEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.Proposal} returns this
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.clearVotingEndTime = function() {
  return this.setVotingEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.Proposal.prototype.hasVotingEndTime = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.TallyResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.TallyResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.TallyResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    yes: jspb.Message.getFieldWithDefault(msg, 1, ""),
    abstain: jspb.Message.getFieldWithDefault(msg, 2, ""),
    no: jspb.Message.getFieldWithDefault(msg, 3, ""),
    noWithVeto: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.TallyResult}
 */
proto.cosmos.gov.v1beta1.TallyResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.TallyResult;
  return proto.cosmos.gov.v1beta1.TallyResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.TallyResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.TallyResult}
 */
proto.cosmos.gov.v1beta1.TallyResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbstain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoWithVeto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.TallyResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.TallyResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.TallyResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYes();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAbstain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNoWithVeto();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string yes = 1;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.getYes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.TallyResult} returns this
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.setYes = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string abstain = 2;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.getAbstain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.TallyResult} returns this
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.setAbstain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string no = 3;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.getNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.TallyResult} returns this
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.setNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string no_with_veto = 4;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.getNoWithVeto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.TallyResult} returns this
 */
proto.cosmos.gov.v1beta1.TallyResult.prototype.setNoWithVeto = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.gov.v1beta1.Vote.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.Vote.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.Vote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.Vote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.Vote.toObject = function(includeInstance, msg) {
  var f, obj = {
    proposalId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    voter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    option: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.cosmos.gov.v1beta1.WeightedVoteOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.Vote}
 */
proto.cosmos.gov.v1beta1.Vote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.Vote;
  return proto.cosmos.gov.v1beta1.Vote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.Vote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.Vote}
 */
proto.cosmos.gov.v1beta1.Vote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProposalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVoter(value);
      break;
    case 3:
      var value = /** @type {!proto.cosmos.gov.v1beta1.VoteOption} */ (reader.readEnum());
      msg.setOption(value);
      break;
    case 4:
      var value = new proto.cosmos.gov.v1beta1.WeightedVoteOption;
      reader.readMessage(value,proto.cosmos.gov.v1beta1.WeightedVoteOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.Vote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.Vote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.Vote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.Vote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProposalId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getVoter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOption();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cosmos.gov.v1beta1.WeightedVoteOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 proposal_id = 1;
 * @return {number}
 */
proto.cosmos.gov.v1beta1.Vote.prototype.getProposalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.gov.v1beta1.Vote} returns this
 */
proto.cosmos.gov.v1beta1.Vote.prototype.setProposalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string voter = 2;
 * @return {string}
 */
proto.cosmos.gov.v1beta1.Vote.prototype.getVoter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.gov.v1beta1.Vote} returns this
 */
proto.cosmos.gov.v1beta1.Vote.prototype.setVoter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VoteOption option = 3;
 * @return {!proto.cosmos.gov.v1beta1.VoteOption}
 */
proto.cosmos.gov.v1beta1.Vote.prototype.getOption = function() {
  return /** @type {!proto.cosmos.gov.v1beta1.VoteOption} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cosmos.gov.v1beta1.VoteOption} value
 * @return {!proto.cosmos.gov.v1beta1.Vote} returns this
 */
proto.cosmos.gov.v1beta1.Vote.prototype.setOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated WeightedVoteOption options = 4;
 * @return {!Array<!proto.cosmos.gov.v1beta1.WeightedVoteOption>}
 */
proto.cosmos.gov.v1beta1.Vote.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.cosmos.gov.v1beta1.WeightedVoteOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cosmos.gov.v1beta1.WeightedVoteOption, 4));
};


/**
 * @param {!Array<!proto.cosmos.gov.v1beta1.WeightedVoteOption>} value
 * @return {!proto.cosmos.gov.v1beta1.Vote} returns this
*/
proto.cosmos.gov.v1beta1.Vote.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cosmos.gov.v1beta1.WeightedVoteOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.gov.v1beta1.WeightedVoteOption}
 */
proto.cosmos.gov.v1beta1.Vote.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cosmos.gov.v1beta1.WeightedVoteOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.gov.v1beta1.Vote} returns this
 */
proto.cosmos.gov.v1beta1.Vote.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.gov.v1beta1.DepositParams.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.DepositParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.DepositParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.DepositParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    minDepositList: jspb.Message.toObjectList(msg.getMinDepositList(),
    cosmos_base_v1beta1_coin_pb.Coin.toObject, includeInstance),
    maxDepositPeriod: (f = msg.getMaxDepositPeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.DepositParams}
 */
proto.cosmos.gov.v1beta1.DepositParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.DepositParams;
  return proto.cosmos.gov.v1beta1.DepositParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.DepositParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.DepositParams}
 */
proto.cosmos.gov.v1beta1.DepositParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_v1beta1_coin_pb.Coin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.Coin.deserializeBinaryFromReader);
      msg.addMinDeposit(value);
      break;
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setMaxDepositPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.DepositParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.DepositParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.DepositParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinDepositList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_base_v1beta1_coin_pb.Coin.serializeBinaryToWriter
    );
  }
  f = message.getMaxDepositPeriod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated cosmos.base.v1beta1.Coin min_deposit = 1;
 * @return {!Array<!proto.cosmos.base.v1beta1.Coin>}
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.getMinDepositList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.Coin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.Coin, 1));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.Coin>} value
 * @return {!proto.cosmos.gov.v1beta1.DepositParams} returns this
*/
proto.cosmos.gov.v1beta1.DepositParams.prototype.setMinDepositList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.Coin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.Coin}
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.addMinDeposit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.base.v1beta1.Coin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.gov.v1beta1.DepositParams} returns this
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.clearMinDepositList = function() {
  return this.setMinDepositList([]);
};


/**
 * optional google.protobuf.Duration max_deposit_period = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.getMaxDepositPeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.DepositParams} returns this
*/
proto.cosmos.gov.v1beta1.DepositParams.prototype.setMaxDepositPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.DepositParams} returns this
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.clearMaxDepositPeriod = function() {
  return this.setMaxDepositPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.DepositParams.prototype.hasMaxDepositPeriod = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.VotingParams.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.VotingParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.VotingParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.VotingParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    votingPeriod: (f = msg.getVotingPeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.VotingParams}
 */
proto.cosmos.gov.v1beta1.VotingParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.VotingParams;
  return proto.cosmos.gov.v1beta1.VotingParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.VotingParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.VotingParams}
 */
proto.cosmos.gov.v1beta1.VotingParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setVotingPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.VotingParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.VotingParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.VotingParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.VotingParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVotingPeriod();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Duration voting_period = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.cosmos.gov.v1beta1.VotingParams.prototype.getVotingPeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.cosmos.gov.v1beta1.VotingParams} returns this
*/
proto.cosmos.gov.v1beta1.VotingParams.prototype.setVotingPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.gov.v1beta1.VotingParams} returns this
 */
proto.cosmos.gov.v1beta1.VotingParams.prototype.clearVotingPeriod = function() {
  return this.setVotingPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.gov.v1beta1.VotingParams.prototype.hasVotingPeriod = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.gov.v1beta1.TallyParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.gov.v1beta1.TallyParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.TallyParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    quorum: msg.getQuorum_asB64(),
    threshold: msg.getThreshold_asB64(),
    vetoThreshold: msg.getVetoThreshold_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.gov.v1beta1.TallyParams}
 */
proto.cosmos.gov.v1beta1.TallyParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.gov.v1beta1.TallyParams;
  return proto.cosmos.gov.v1beta1.TallyParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.gov.v1beta1.TallyParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.gov.v1beta1.TallyParams}
 */
proto.cosmos.gov.v1beta1.TallyParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setQuorum(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setThreshold(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVetoThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.gov.v1beta1.TallyParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.gov.v1beta1.TallyParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.gov.v1beta1.TallyParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuorum_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getThreshold_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getVetoThreshold_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes quorum = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getQuorum = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes quorum = 1;
 * This is a type-conversion wrapper around `getQuorum()`
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getQuorum_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getQuorum()));
};


/**
 * optional bytes quorum = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getQuorum()`
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getQuorum_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getQuorum()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cosmos.gov.v1beta1.TallyParams} returns this
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.setQuorum = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes threshold = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getThreshold = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes threshold = 2;
 * This is a type-conversion wrapper around `getThreshold()`
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getThreshold_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getThreshold()));
};


/**
 * optional bytes threshold = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThreshold()`
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getThreshold_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getThreshold()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cosmos.gov.v1beta1.TallyParams} returns this
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes veto_threshold = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getVetoThreshold = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes veto_threshold = 3;
 * This is a type-conversion wrapper around `getVetoThreshold()`
 * @return {string}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getVetoThreshold_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVetoThreshold()));
};


/**
 * optional bytes veto_threshold = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVetoThreshold()`
 * @return {!Uint8Array}
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.getVetoThreshold_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVetoThreshold()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cosmos.gov.v1beta1.TallyParams} returns this
 */
proto.cosmos.gov.v1beta1.TallyParams.prototype.setVetoThreshold = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.cosmos.gov.v1beta1.VoteOption = {
  VOTE_OPTION_UNSPECIFIED: 0,
  VOTE_OPTION_YES: 1,
  VOTE_OPTION_ABSTAIN: 2,
  VOTE_OPTION_NO: 3,
  VOTE_OPTION_NO_WITH_VETO: 4
};

/**
 * @enum {number}
 */
proto.cosmos.gov.v1beta1.ProposalStatus = {
  PROPOSAL_STATUS_UNSPECIFIED: 0,
  PROPOSAL_STATUS_DEPOSIT_PERIOD: 1,
  PROPOSAL_STATUS_VOTING_PERIOD: 2,
  PROPOSAL_STATUS_PASSED: 3,
  PROPOSAL_STATUS_REJECTED: 4,
  PROPOSAL_STATUS_FAILED: 5
};

goog.object.extend(exports, proto.cosmos.gov.v1beta1);
