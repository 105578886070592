"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.promiseTimeout = exports.bip44ToArray = exports.isBip44 = void 0;
function isBip44(path) {
    let result = path.match(/m\/\d+'\/\d+'\/\d+'\/\d+\/\d+$/);
    return !!result;
}
exports.isBip44 = isBip44;
function bip44ToArray(path) {
    var _a;
    if (!isBip44(path))
        throw Error('Not BIP 44');
    let result = path.match(/\d+/g);
    if (((_a = result === null || result === void 0 ? void 0 : result.length) !== null && _a !== void 0 ? _a : 0) !== 5)
        throw Error('Not BIP 44');
    return result.map((x) => parseInt(x));
}
exports.bip44ToArray = bip44ToArray;
function promiseTimeout(promise, timeout) {
    return __awaiter(this, void 0, void 0, function* () {
        let timer;
        return Promise.race([
            promise.then((value) => {
                clearTimeout(timer);
                return value;
            }),
            new Promise((rs, _) => {
                timer = setTimeout(rs, timeout);
                return undefined;
            }),
        ]);
    });
}
exports.promiseTimeout = promiseTimeout;
