// source: cosmos/tx/v1beta1/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var cosmos_base_abci_v1beta1_abci_pb = require('../../../cosmos/base/abci/v1beta1/abci_pb.js');
goog.object.extend(proto, cosmos_base_abci_v1beta1_abci_pb);
var cosmos_tx_v1beta1_tx_pb = require('../../../cosmos/tx/v1beta1/tx_pb.js');
goog.object.extend(proto, cosmos_tx_v1beta1_tx_pb);
var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var cosmos_base_query_v1beta1_pagination_pb = require('../../../cosmos/base/query/v1beta1/pagination_pb.js');
goog.object.extend(proto, cosmos_base_query_v1beta1_pagination_pb);
goog.exportSymbol('proto.cosmos.tx.v1beta1.BroadcastMode', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.BroadcastTxRequest', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.BroadcastTxResponse', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.GetTxRequest', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.GetTxResponse', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.GetTxsEventRequest', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.GetTxsEventResponse', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.OrderBy', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.SimulateRequest', null, global);
goog.exportSymbol('proto.cosmos.tx.v1beta1.SimulateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.tx.v1beta1.GetTxsEventRequest.repeatedFields_, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.GetTxsEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.GetTxsEventRequest.displayName = 'proto.cosmos.tx.v1beta1.GetTxsEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.tx.v1beta1.GetTxsEventResponse.repeatedFields_, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.GetTxsEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.GetTxsEventResponse.displayName = 'proto.cosmos.tx.v1beta1.GetTxsEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.BroadcastTxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.BroadcastTxRequest.displayName = 'proto.cosmos.tx.v1beta1.BroadcastTxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.BroadcastTxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.BroadcastTxResponse.displayName = 'proto.cosmos.tx.v1beta1.BroadcastTxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.SimulateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.SimulateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.SimulateRequest.displayName = 'proto.cosmos.tx.v1beta1.SimulateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.SimulateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.SimulateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.SimulateResponse.displayName = 'proto.cosmos.tx.v1beta1.SimulateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.GetTxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.GetTxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.GetTxRequest.displayName = 'proto.cosmos.tx.v1beta1.GetTxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.tx.v1beta1.GetTxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.tx.v1beta1.GetTxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.tx.v1beta1.GetTxResponse.displayName = 'proto.cosmos.tx.v1beta1.GetTxResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.GetTxsEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    pagination: (f = msg.getPagination()) && cosmos_base_query_v1beta1_pagination_pb.PageRequest.toObject(includeInstance, f),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.GetTxsEventRequest;
  return proto.cosmos.tx.v1beta1.GetTxsEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEvents(value);
      break;
    case 2:
      var value = new cosmos_base_query_v1beta1_pagination_pb.PageRequest;
      reader.readMessage(value,cosmos_base_query_v1beta1_pagination_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {!proto.cosmos.tx.v1beta1.OrderBy} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.GetTxsEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      cosmos_base_query_v1beta1_pagination_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated string events = 1;
 * @return {!Array<string>}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.getEventsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.setEventsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.addEvents = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional cosmos.base.query.v1beta1.PageRequest pagination = 2;
 * @return {?proto.cosmos.base.query.v1beta1.PageRequest}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.getPagination = function() {
  return /** @type{?proto.cosmos.base.query.v1beta1.PageRequest} */ (
    jspb.Message.getWrapperField(this, cosmos_base_query_v1beta1_pagination_pb.PageRequest, 2));
};


/**
 * @param {?proto.cosmos.base.query.v1beta1.PageRequest|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} returns this
*/
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OrderBy order_by = 3;
 * @return {!proto.cosmos.tx.v1beta1.OrderBy}
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.cosmos.tx.v1beta1.OrderBy} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cosmos.tx.v1beta1.OrderBy} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventRequest} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.GetTxsEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    txsList: jspb.Message.toObjectList(msg.getTxsList(),
    cosmos_tx_v1beta1_tx_pb.Tx.toObject, includeInstance),
    txResponsesList: jspb.Message.toObjectList(msg.getTxResponsesList(),
    cosmos_base_abci_v1beta1_abci_pb.TxResponse.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && cosmos_base_query_v1beta1_pagination_pb.PageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.GetTxsEventResponse;
  return proto.cosmos.tx.v1beta1.GetTxsEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_tx_v1beta1_tx_pb.Tx;
      reader.readMessage(value,cosmos_tx_v1beta1_tx_pb.Tx.deserializeBinaryFromReader);
      msg.addTxs(value);
      break;
    case 2:
      var value = new cosmos_base_abci_v1beta1_abci_pb.TxResponse;
      reader.readMessage(value,cosmos_base_abci_v1beta1_abci_pb.TxResponse.deserializeBinaryFromReader);
      msg.addTxResponses(value);
      break;
    case 3:
      var value = new cosmos_base_query_v1beta1_pagination_pb.PageResponse;
      reader.readMessage(value,cosmos_base_query_v1beta1_pagination_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.GetTxsEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cosmos_tx_v1beta1_tx_pb.Tx.serializeBinaryToWriter
    );
  }
  f = message.getTxResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      cosmos_base_abci_v1beta1_abci_pb.TxResponse.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      cosmos_base_query_v1beta1_pagination_pb.PageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tx txs = 1;
 * @return {!Array<!proto.cosmos.tx.v1beta1.Tx>}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.getTxsList = function() {
  return /** @type{!Array<!proto.cosmos.tx.v1beta1.Tx>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_tx_v1beta1_tx_pb.Tx, 1));
};


/**
 * @param {!Array<!proto.cosmos.tx.v1beta1.Tx>} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} returns this
*/
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.setTxsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.tx.v1beta1.Tx=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.tx.v1beta1.Tx}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.addTxs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.tx.v1beta1.Tx, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.clearTxsList = function() {
  return this.setTxsList([]);
};


/**
 * repeated cosmos.base.abci.v1beta1.TxResponse tx_responses = 2;
 * @return {!Array<!proto.cosmos.base.abci.v1beta1.TxResponse>}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.getTxResponsesList = function() {
  return /** @type{!Array<!proto.cosmos.base.abci.v1beta1.TxResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_abci_v1beta1_abci_pb.TxResponse, 2));
};


/**
 * @param {!Array<!proto.cosmos.base.abci.v1beta1.TxResponse>} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} returns this
*/
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.setTxResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cosmos.base.abci.v1beta1.TxResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.abci.v1beta1.TxResponse}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.addTxResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cosmos.base.abci.v1beta1.TxResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.clearTxResponsesList = function() {
  return this.setTxResponsesList([]);
};


/**
 * optional cosmos.base.query.v1beta1.PageResponse pagination = 3;
 * @return {?proto.cosmos.base.query.v1beta1.PageResponse}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.getPagination = function() {
  return /** @type{?proto.cosmos.base.query.v1beta1.PageResponse} */ (
    jspb.Message.getWrapperField(this, cosmos_base_query_v1beta1_pagination_pb.PageResponse, 3));
};


/**
 * @param {?proto.cosmos.base.query.v1beta1.PageResponse|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} returns this
*/
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.GetTxsEventResponse} returns this
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.GetTxsEventResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.BroadcastTxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.BroadcastTxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    txBytes: msg.getTxBytes_asB64(),
    mode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxRequest}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.BroadcastTxRequest;
  return proto.cosmos.tx.v1beta1.BroadcastTxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.BroadcastTxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxRequest}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTxBytes(value);
      break;
    case 2:
      var value = /** @type {!proto.cosmos.tx.v1beta1.BroadcastMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.BroadcastTxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.BroadcastTxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bytes tx_bytes = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.getTxBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes tx_bytes = 1;
 * This is a type-conversion wrapper around `getTxBytes()`
 * @return {string}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.getTxBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTxBytes()));
};


/**
 * optional bytes tx_bytes = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTxBytes()`
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.getTxBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTxBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxRequest} returns this
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.setTxBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional BroadcastMode mode = 2;
 * @return {!proto.cosmos.tx.v1beta1.BroadcastMode}
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.getMode = function() {
  return /** @type {!proto.cosmos.tx.v1beta1.BroadcastMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cosmos.tx.v1beta1.BroadcastMode} value
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxRequest} returns this
 */
proto.cosmos.tx.v1beta1.BroadcastTxRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.BroadcastTxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.BroadcastTxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    txResponse: (f = msg.getTxResponse()) && cosmos_base_abci_v1beta1_abci_pb.TxResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxResponse}
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.BroadcastTxResponse;
  return proto.cosmos.tx.v1beta1.BroadcastTxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.BroadcastTxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxResponse}
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_abci_v1beta1_abci_pb.TxResponse;
      reader.readMessage(value,cosmos_base_abci_v1beta1_abci_pb.TxResponse.deserializeBinaryFromReader);
      msg.setTxResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.BroadcastTxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.BroadcastTxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      cosmos_base_abci_v1beta1_abci_pb.TxResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional cosmos.base.abci.v1beta1.TxResponse tx_response = 1;
 * @return {?proto.cosmos.base.abci.v1beta1.TxResponse}
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.prototype.getTxResponse = function() {
  return /** @type{?proto.cosmos.base.abci.v1beta1.TxResponse} */ (
    jspb.Message.getWrapperField(this, cosmos_base_abci_v1beta1_abci_pb.TxResponse, 1));
};


/**
 * @param {?proto.cosmos.base.abci.v1beta1.TxResponse|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxResponse} returns this
*/
proto.cosmos.tx.v1beta1.BroadcastTxResponse.prototype.setTxResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.BroadcastTxResponse} returns this
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.prototype.clearTxResponse = function() {
  return this.setTxResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.BroadcastTxResponse.prototype.hasTxResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.SimulateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.SimulateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.SimulateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tx: (f = msg.getTx()) && cosmos_tx_v1beta1_tx_pb.Tx.toObject(includeInstance, f),
    txBytes: msg.getTxBytes_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.SimulateRequest}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.SimulateRequest;
  return proto.cosmos.tx.v1beta1.SimulateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.SimulateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.SimulateRequest}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_tx_v1beta1_tx_pb.Tx;
      reader.readMessage(value,cosmos_tx_v1beta1_tx_pb.Tx.deserializeBinaryFromReader);
      msg.setTx(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTxBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.SimulateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.SimulateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.SimulateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTx();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      cosmos_tx_v1beta1_tx_pb.Tx.serializeBinaryToWriter
    );
  }
  f = message.getTxBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Tx tx = 1;
 * @return {?proto.cosmos.tx.v1beta1.Tx}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.getTx = function() {
  return /** @type{?proto.cosmos.tx.v1beta1.Tx} */ (
    jspb.Message.getWrapperField(this, cosmos_tx_v1beta1_tx_pb.Tx, 1));
};


/**
 * @param {?proto.cosmos.tx.v1beta1.Tx|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.SimulateRequest} returns this
*/
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.setTx = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.SimulateRequest} returns this
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.clearTx = function() {
  return this.setTx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.hasTx = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes tx_bytes = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.getTxBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes tx_bytes = 2;
 * This is a type-conversion wrapper around `getTxBytes()`
 * @return {string}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.getTxBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTxBytes()));
};


/**
 * optional bytes tx_bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTxBytes()`
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.getTxBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTxBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cosmos.tx.v1beta1.SimulateRequest} returns this
 */
proto.cosmos.tx.v1beta1.SimulateRequest.prototype.setTxBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.SimulateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.SimulateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.SimulateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gasInfo: (f = msg.getGasInfo()) && cosmos_base_abci_v1beta1_abci_pb.GasInfo.toObject(includeInstance, f),
    result: (f = msg.getResult()) && cosmos_base_abci_v1beta1_abci_pb.Result.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.SimulateResponse}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.SimulateResponse;
  return proto.cosmos.tx.v1beta1.SimulateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.SimulateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.SimulateResponse}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_base_abci_v1beta1_abci_pb.GasInfo;
      reader.readMessage(value,cosmos_base_abci_v1beta1_abci_pb.GasInfo.deserializeBinaryFromReader);
      msg.setGasInfo(value);
      break;
    case 2:
      var value = new cosmos_base_abci_v1beta1_abci_pb.Result;
      reader.readMessage(value,cosmos_base_abci_v1beta1_abci_pb.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.SimulateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.SimulateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.SimulateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGasInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      cosmos_base_abci_v1beta1_abci_pb.GasInfo.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      cosmos_base_abci_v1beta1_abci_pb.Result.serializeBinaryToWriter
    );
  }
};


/**
 * optional cosmos.base.abci.v1beta1.GasInfo gas_info = 1;
 * @return {?proto.cosmos.base.abci.v1beta1.GasInfo}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.getGasInfo = function() {
  return /** @type{?proto.cosmos.base.abci.v1beta1.GasInfo} */ (
    jspb.Message.getWrapperField(this, cosmos_base_abci_v1beta1_abci_pb.GasInfo, 1));
};


/**
 * @param {?proto.cosmos.base.abci.v1beta1.GasInfo|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.SimulateResponse} returns this
*/
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.setGasInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.SimulateResponse} returns this
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.clearGasInfo = function() {
  return this.setGasInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.hasGasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cosmos.base.abci.v1beta1.Result result = 2;
 * @return {?proto.cosmos.base.abci.v1beta1.Result}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.getResult = function() {
  return /** @type{?proto.cosmos.base.abci.v1beta1.Result} */ (
    jspb.Message.getWrapperField(this, cosmos_base_abci_v1beta1_abci_pb.Result, 2));
};


/**
 * @param {?proto.cosmos.base.abci.v1beta1.Result|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.SimulateResponse} returns this
*/
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.SimulateResponse} returns this
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.SimulateResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.GetTxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.GetTxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.GetTxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.GetTxRequest}
 */
proto.cosmos.tx.v1beta1.GetTxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.GetTxRequest;
  return proto.cosmos.tx.v1beta1.GetTxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.GetTxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.GetTxRequest}
 */
proto.cosmos.tx.v1beta1.GetTxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.GetTxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.GetTxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.GetTxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.cosmos.tx.v1beta1.GetTxRequest.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxRequest} returns this
 */
proto.cosmos.tx.v1beta1.GetTxRequest.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.tx.v1beta1.GetTxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.tx.v1beta1.GetTxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tx: (f = msg.getTx()) && cosmos_tx_v1beta1_tx_pb.Tx.toObject(includeInstance, f),
    txResponse: (f = msg.getTxResponse()) && cosmos_base_abci_v1beta1_abci_pb.TxResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.tx.v1beta1.GetTxResponse}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.tx.v1beta1.GetTxResponse;
  return proto.cosmos.tx.v1beta1.GetTxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.tx.v1beta1.GetTxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.tx.v1beta1.GetTxResponse}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cosmos_tx_v1beta1_tx_pb.Tx;
      reader.readMessage(value,cosmos_tx_v1beta1_tx_pb.Tx.deserializeBinaryFromReader);
      msg.setTx(value);
      break;
    case 2:
      var value = new cosmos_base_abci_v1beta1_abci_pb.TxResponse;
      reader.readMessage(value,cosmos_base_abci_v1beta1_abci_pb.TxResponse.deserializeBinaryFromReader);
      msg.setTxResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.tx.v1beta1.GetTxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.tx.v1beta1.GetTxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.tx.v1beta1.GetTxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTx();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      cosmos_tx_v1beta1_tx_pb.Tx.serializeBinaryToWriter
    );
  }
  f = message.getTxResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      cosmos_base_abci_v1beta1_abci_pb.TxResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional Tx tx = 1;
 * @return {?proto.cosmos.tx.v1beta1.Tx}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.getTx = function() {
  return /** @type{?proto.cosmos.tx.v1beta1.Tx} */ (
    jspb.Message.getWrapperField(this, cosmos_tx_v1beta1_tx_pb.Tx, 1));
};


/**
 * @param {?proto.cosmos.tx.v1beta1.Tx|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxResponse} returns this
*/
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.setTx = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.GetTxResponse} returns this
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.clearTx = function() {
  return this.setTx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.hasTx = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cosmos.base.abci.v1beta1.TxResponse tx_response = 2;
 * @return {?proto.cosmos.base.abci.v1beta1.TxResponse}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.getTxResponse = function() {
  return /** @type{?proto.cosmos.base.abci.v1beta1.TxResponse} */ (
    jspb.Message.getWrapperField(this, cosmos_base_abci_v1beta1_abci_pb.TxResponse, 2));
};


/**
 * @param {?proto.cosmos.base.abci.v1beta1.TxResponse|undefined} value
 * @return {!proto.cosmos.tx.v1beta1.GetTxResponse} returns this
*/
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.setTxResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.tx.v1beta1.GetTxResponse} returns this
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.clearTxResponse = function() {
  return this.setTxResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.tx.v1beta1.GetTxResponse.prototype.hasTxResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.cosmos.tx.v1beta1.OrderBy = {
  ORDER_BY_UNSPECIFIED: 0,
  ORDER_BY_ASC: 1,
  ORDER_BY_DESC: 2
};

/**
 * @enum {number}
 */
proto.cosmos.tx.v1beta1.BroadcastMode = {
  BROADCAST_MODE_UNSPECIFIED: 0,
  BROADCAST_MODE_BLOCK: 1,
  BROADCAST_MODE_SYNC: 2,
  BROADCAST_MODE_ASYNC: 3
};

goog.object.extend(exports, proto.cosmos.tx.v1beta1);
