// source: oracle/v1/oracle.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var gogoproto_gogo_pb = require('../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var cosmos_base_v1beta1_coin_pb = require('../../cosmos/base/v1beta1/coin_pb.js');
goog.object.extend(proto, cosmos_base_v1beta1_coin_pb);
goog.exportSymbol('proto.oracle.v1.ActiveValidator', null, global);
goog.exportSymbol('proto.oracle.v1.DataSource', null, global);
goog.exportSymbol('proto.oracle.v1.IBCChannel', null, global);
goog.exportSymbol('proto.oracle.v1.OracleRequestPacketAcknowledgement', null, global);
goog.exportSymbol('proto.oracle.v1.OracleRequestPacketData', null, global);
goog.exportSymbol('proto.oracle.v1.OracleResponsePacketData', null, global);
goog.exportSymbol('proto.oracle.v1.OracleScript', null, global);
goog.exportSymbol('proto.oracle.v1.Params', null, global);
goog.exportSymbol('proto.oracle.v1.PendingResolveList', null, global);
goog.exportSymbol('proto.oracle.v1.PriceResult', null, global);
goog.exportSymbol('proto.oracle.v1.RawReport', null, global);
goog.exportSymbol('proto.oracle.v1.RawRequest', null, global);
goog.exportSymbol('proto.oracle.v1.Report', null, global);
goog.exportSymbol('proto.oracle.v1.Request', null, global);
goog.exportSymbol('proto.oracle.v1.RequestVerification', null, global);
goog.exportSymbol('proto.oracle.v1.ResolveStatus', null, global);
goog.exportSymbol('proto.oracle.v1.Result', null, global);
goog.exportSymbol('proto.oracle.v1.ValidatorStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.DataSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oracle.v1.DataSource.repeatedFields_, null);
};
goog.inherits(proto.oracle.v1.DataSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.DataSource.displayName = 'proto.oracle.v1.DataSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.OracleScript = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.OracleScript, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.OracleScript.displayName = 'proto.oracle.v1.OracleScript';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.RawRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.RawRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.RawRequest.displayName = 'proto.oracle.v1.RawRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.RawReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.RawReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.RawReport.displayName = 'proto.oracle.v1.RawReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oracle.v1.Request.repeatedFields_, null);
};
goog.inherits(proto.oracle.v1.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.Request.displayName = 'proto.oracle.v1.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oracle.v1.Report.repeatedFields_, null);
};
goog.inherits(proto.oracle.v1.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.Report.displayName = 'proto.oracle.v1.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.OracleRequestPacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oracle.v1.OracleRequestPacketData.repeatedFields_, null);
};
goog.inherits(proto.oracle.v1.OracleRequestPacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.OracleRequestPacketData.displayName = 'proto.oracle.v1.OracleRequestPacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.OracleRequestPacketAcknowledgement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.OracleRequestPacketAcknowledgement.displayName = 'proto.oracle.v1.OracleRequestPacketAcknowledgement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.OracleResponsePacketData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.OracleResponsePacketData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.OracleResponsePacketData.displayName = 'proto.oracle.v1.OracleResponsePacketData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.Result.displayName = 'proto.oracle.v1.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.ValidatorStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.ValidatorStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.ValidatorStatus.displayName = 'proto.oracle.v1.ValidatorStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.ActiveValidator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.ActiveValidator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.ActiveValidator.displayName = 'proto.oracle.v1.ActiveValidator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.Params.displayName = 'proto.oracle.v1.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.PendingResolveList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oracle.v1.PendingResolveList.repeatedFields_, null);
};
goog.inherits(proto.oracle.v1.PendingResolveList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.PendingResolveList.displayName = 'proto.oracle.v1.PendingResolveList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.IBCChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.IBCChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.IBCChannel.displayName = 'proto.oracle.v1.IBCChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.RequestVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.RequestVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.RequestVerification.displayName = 'proto.oracle.v1.RequestVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oracle.v1.PriceResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oracle.v1.PriceResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oracle.v1.PriceResult.displayName = 'proto.oracle.v1.PriceResult';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oracle.v1.DataSource.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.DataSource.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.DataSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.DataSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.DataSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    owner: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    treasury: jspb.Message.getFieldWithDefault(msg, 5, ""),
    feeList: jspb.Message.toObjectList(msg.getFeeList(),
    cosmos_base_v1beta1_coin_pb.Coin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.DataSource}
 */
proto.oracle.v1.DataSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.DataSource;
  return proto.oracle.v1.DataSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.DataSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.DataSource}
 */
proto.oracle.v1.DataSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasury(value);
      break;
    case 6:
      var value = new cosmos_base_v1beta1_coin_pb.Coin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.Coin.deserializeBinaryFromReader);
      msg.addFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.DataSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.DataSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.DataSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.DataSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTreasury();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFeeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      cosmos_base_v1beta1_coin_pb.Coin.serializeBinaryToWriter
    );
  }
};


/**
 * optional string owner = 1;
 * @return {string}
 */
proto.oracle.v1.DataSource.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.DataSource} returns this
 */
proto.oracle.v1.DataSource.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.oracle.v1.DataSource.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.DataSource} returns this
 */
proto.oracle.v1.DataSource.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.oracle.v1.DataSource.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.DataSource} returns this
 */
proto.oracle.v1.DataSource.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filename = 4;
 * @return {string}
 */
proto.oracle.v1.DataSource.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.DataSource} returns this
 */
proto.oracle.v1.DataSource.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string treasury = 5;
 * @return {string}
 */
proto.oracle.v1.DataSource.prototype.getTreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.DataSource} returns this
 */
proto.oracle.v1.DataSource.prototype.setTreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated cosmos.base.v1beta1.Coin fee = 6;
 * @return {!Array<!proto.cosmos.base.v1beta1.Coin>}
 */
proto.oracle.v1.DataSource.prototype.getFeeList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.Coin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.Coin, 6));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.Coin>} value
 * @return {!proto.oracle.v1.DataSource} returns this
*/
proto.oracle.v1.DataSource.prototype.setFeeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.Coin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.Coin}
 */
proto.oracle.v1.DataSource.prototype.addFee = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cosmos.base.v1beta1.Coin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oracle.v1.DataSource} returns this
 */
proto.oracle.v1.DataSource.prototype.clearFeeList = function() {
  return this.setFeeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.OracleScript.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.OracleScript.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.OracleScript} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleScript.toObject = function(includeInstance, msg) {
  var f, obj = {
    owner: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    schema: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sourceCodeUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.OracleScript}
 */
proto.oracle.v1.OracleScript.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.OracleScript;
  return proto.oracle.v1.OracleScript.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.OracleScript} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.OracleScript}
 */
proto.oracle.v1.OracleScript.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchema(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceCodeUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.OracleScript.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.OracleScript.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.OracleScript} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleScript.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSourceCodeUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string owner = 1;
 * @return {string}
 */
proto.oracle.v1.OracleScript.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleScript} returns this
 */
proto.oracle.v1.OracleScript.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.oracle.v1.OracleScript.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleScript} returns this
 */
proto.oracle.v1.OracleScript.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.oracle.v1.OracleScript.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleScript} returns this
 */
proto.oracle.v1.OracleScript.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filename = 4;
 * @return {string}
 */
proto.oracle.v1.OracleScript.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleScript} returns this
 */
proto.oracle.v1.OracleScript.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string schema = 5;
 * @return {string}
 */
proto.oracle.v1.OracleScript.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleScript} returns this
 */
proto.oracle.v1.OracleScript.prototype.setSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string source_code_url = 6;
 * @return {string}
 */
proto.oracle.v1.OracleScript.prototype.getSourceCodeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleScript} returns this
 */
proto.oracle.v1.OracleScript.prototype.setSourceCodeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.RawRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.RawRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.RawRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.RawRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataSourceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    calldata: msg.getCalldata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.RawRequest}
 */
proto.oracle.v1.RawRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.RawRequest;
  return proto.oracle.v1.RawRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.RawRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.RawRequest}
 */
proto.oracle.v1.RawRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDataSourceId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCalldata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.RawRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.RawRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.RawRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.RawRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDataSourceId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCalldata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional uint64 external_id = 1;
 * @return {number}
 */
proto.oracle.v1.RawRequest.prototype.getExternalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.RawRequest} returns this
 */
proto.oracle.v1.RawRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 data_source_id = 2;
 * @return {number}
 */
proto.oracle.v1.RawRequest.prototype.getDataSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.RawRequest} returns this
 */
proto.oracle.v1.RawRequest.prototype.setDataSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes calldata = 3;
 * @return {!(string|Uint8Array)}
 */
proto.oracle.v1.RawRequest.prototype.getCalldata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes calldata = 3;
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {string}
 */
proto.oracle.v1.RawRequest.prototype.getCalldata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCalldata()));
};


/**
 * optional bytes calldata = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {!Uint8Array}
 */
proto.oracle.v1.RawRequest.prototype.getCalldata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCalldata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.oracle.v1.RawRequest} returns this
 */
proto.oracle.v1.RawRequest.prototype.setCalldata = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.RawReport.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.RawReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.RawReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.RawReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    exitCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.RawReport}
 */
proto.oracle.v1.RawReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.RawReport;
  return proto.oracle.v1.RawReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.RawReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.RawReport}
 */
proto.oracle.v1.RawReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExternalId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExitCode(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.RawReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.RawReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.RawReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.RawReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getExitCode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional uint64 external_id = 1;
 * @return {number}
 */
proto.oracle.v1.RawReport.prototype.getExternalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.RawReport} returns this
 */
proto.oracle.v1.RawReport.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 exit_code = 2;
 * @return {number}
 */
proto.oracle.v1.RawReport.prototype.getExitCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.RawReport} returns this
 */
proto.oracle.v1.RawReport.prototype.setExitCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.oracle.v1.RawReport.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.oracle.v1.RawReport.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.oracle.v1.RawReport.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.oracle.v1.RawReport} returns this
 */
proto.oracle.v1.RawReport.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oracle.v1.Request.repeatedFields_ = [3,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    oracleScriptId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    calldata: msg.getCalldata_asB64(),
    requestedValidatorsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    minCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    requestHeight: jspb.Message.getFieldWithDefault(msg, 5, 0),
    requestTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    clientId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rawRequestsList: jspb.Message.toObjectList(msg.getRawRequestsList(),
    proto.oracle.v1.RawRequest.toObject, includeInstance),
    ibcChannel: (f = msg.getIbcChannel()) && proto.oracle.v1.IBCChannel.toObject(includeInstance, f),
    executeGas: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.Request}
 */
proto.oracle.v1.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.Request;
  return proto.oracle.v1.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.Request}
 */
proto.oracle.v1.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOracleScriptId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCalldata(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRequestedValidators(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestHeight(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 8:
      var value = new proto.oracle.v1.RawRequest;
      reader.readMessage(value,proto.oracle.v1.RawRequest.deserializeBinaryFromReader);
      msg.addRawRequests(value);
      break;
    case 9:
      var value = new proto.oracle.v1.IBCChannel;
      reader.readMessage(value,proto.oracle.v1.IBCChannel.deserializeBinaryFromReader);
      msg.setIbcChannel(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecuteGas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOracleScriptId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCalldata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getRequestedValidatorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getMinCount();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getRequestHeight();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRequestTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRawRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.oracle.v1.RawRequest.serializeBinaryToWriter
    );
  }
  f = message.getIbcChannel();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.oracle.v1.IBCChannel.serializeBinaryToWriter
    );
  }
  f = message.getExecuteGas();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
};


/**
 * optional uint64 oracle_script_id = 1;
 * @return {number}
 */
proto.oracle.v1.Request.prototype.getOracleScriptId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setOracleScriptId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes calldata = 2;
 * @return {!(string|Uint8Array)}
 */
proto.oracle.v1.Request.prototype.getCalldata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes calldata = 2;
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {string}
 */
proto.oracle.v1.Request.prototype.getCalldata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCalldata()));
};


/**
 * optional bytes calldata = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {!Uint8Array}
 */
proto.oracle.v1.Request.prototype.getCalldata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCalldata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setCalldata = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated string requested_validators = 3;
 * @return {!Array<string>}
 */
proto.oracle.v1.Request.prototype.getRequestedValidatorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setRequestedValidatorsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.addRequestedValidators = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.clearRequestedValidatorsList = function() {
  return this.setRequestedValidatorsList([]);
};


/**
 * optional uint64 min_count = 4;
 * @return {number}
 */
proto.oracle.v1.Request.prototype.getMinCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setMinCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 request_height = 5;
 * @return {number}
 */
proto.oracle.v1.Request.prototype.getRequestHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setRequestHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 request_time = 6;
 * @return {number}
 */
proto.oracle.v1.Request.prototype.getRequestTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setRequestTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string client_id = 7;
 * @return {string}
 */
proto.oracle.v1.Request.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated RawRequest raw_requests = 8;
 * @return {!Array<!proto.oracle.v1.RawRequest>}
 */
proto.oracle.v1.Request.prototype.getRawRequestsList = function() {
  return /** @type{!Array<!proto.oracle.v1.RawRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oracle.v1.RawRequest, 8));
};


/**
 * @param {!Array<!proto.oracle.v1.RawRequest>} value
 * @return {!proto.oracle.v1.Request} returns this
*/
proto.oracle.v1.Request.prototype.setRawRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.oracle.v1.RawRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oracle.v1.RawRequest}
 */
proto.oracle.v1.Request.prototype.addRawRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.oracle.v1.RawRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.clearRawRequestsList = function() {
  return this.setRawRequestsList([]);
};


/**
 * optional IBCChannel ibc_channel = 9;
 * @return {?proto.oracle.v1.IBCChannel}
 */
proto.oracle.v1.Request.prototype.getIbcChannel = function() {
  return /** @type{?proto.oracle.v1.IBCChannel} */ (
    jspb.Message.getWrapperField(this, proto.oracle.v1.IBCChannel, 9));
};


/**
 * @param {?proto.oracle.v1.IBCChannel|undefined} value
 * @return {!proto.oracle.v1.Request} returns this
*/
proto.oracle.v1.Request.prototype.setIbcChannel = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.clearIbcChannel = function() {
  return this.setIbcChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oracle.v1.Request.prototype.hasIbcChannel = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint64 execute_gas = 10;
 * @return {number}
 */
proto.oracle.v1.Request.prototype.getExecuteGas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Request} returns this
 */
proto.oracle.v1.Request.prototype.setExecuteGas = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oracle.v1.Report.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    validator: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inBeforeResolve: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    rawReportsList: jspb.Message.toObjectList(msg.getRawReportsList(),
    proto.oracle.v1.RawReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.Report}
 */
proto.oracle.v1.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.Report;
  return proto.oracle.v1.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.Report}
 */
proto.oracle.v1.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidator(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInBeforeResolve(value);
      break;
    case 3:
      var value = new proto.oracle.v1.RawReport;
      reader.readMessage(value,proto.oracle.v1.RawReport.deserializeBinaryFromReader);
      msg.addRawReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidator();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInBeforeResolve();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRawReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.oracle.v1.RawReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string validator = 1;
 * @return {string}
 */
proto.oracle.v1.Report.prototype.getValidator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.Report} returns this
 */
proto.oracle.v1.Report.prototype.setValidator = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool in_before_resolve = 2;
 * @return {boolean}
 */
proto.oracle.v1.Report.prototype.getInBeforeResolve = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oracle.v1.Report} returns this
 */
proto.oracle.v1.Report.prototype.setInBeforeResolve = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated RawReport raw_reports = 3;
 * @return {!Array<!proto.oracle.v1.RawReport>}
 */
proto.oracle.v1.Report.prototype.getRawReportsList = function() {
  return /** @type{!Array<!proto.oracle.v1.RawReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oracle.v1.RawReport, 3));
};


/**
 * @param {!Array<!proto.oracle.v1.RawReport>} value
 * @return {!proto.oracle.v1.Report} returns this
*/
proto.oracle.v1.Report.prototype.setRawReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.oracle.v1.RawReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oracle.v1.RawReport}
 */
proto.oracle.v1.Report.prototype.addRawReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.oracle.v1.RawReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oracle.v1.Report} returns this
 */
proto.oracle.v1.Report.prototype.clearRawReportsList = function() {
  return this.setRawReportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oracle.v1.OracleRequestPacketData.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.OracleRequestPacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.OracleRequestPacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleRequestPacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oracleScriptId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    calldata: msg.getCalldata_asB64(),
    askCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    feeLimitList: jspb.Message.toObjectList(msg.getFeeLimitList(),
    cosmos_base_v1beta1_coin_pb.Coin.toObject, includeInstance),
    prepareGas: jspb.Message.getFieldWithDefault(msg, 7, 0),
    executeGas: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.OracleRequestPacketData}
 */
proto.oracle.v1.OracleRequestPacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.OracleRequestPacketData;
  return proto.oracle.v1.OracleRequestPacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.OracleRequestPacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.OracleRequestPacketData}
 */
proto.oracle.v1.OracleRequestPacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOracleScriptId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCalldata(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAskCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinCount(value);
      break;
    case 6:
      var value = new cosmos_base_v1beta1_coin_pb.Coin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.Coin.deserializeBinaryFromReader);
      msg.addFeeLimit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrepareGas(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecuteGas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.OracleRequestPacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.OracleRequestPacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleRequestPacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOracleScriptId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCalldata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAskCount();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getMinCount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getFeeLimitList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      cosmos_base_v1beta1_coin_pb.Coin.serializeBinaryToWriter
    );
  }
  f = message.getPrepareGas();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getExecuteGas();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 oracle_script_id = 2;
 * @return {number}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getOracleScriptId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.setOracleScriptId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes calldata = 3;
 * @return {!(string|Uint8Array)}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getCalldata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes calldata = 3;
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {string}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getCalldata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCalldata()));
};


/**
 * optional bytes calldata = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {!Uint8Array}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getCalldata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCalldata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.setCalldata = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional uint64 ask_count = 4;
 * @return {number}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getAskCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.setAskCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 min_count = 5;
 * @return {number}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getMinCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.setMinCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated cosmos.base.v1beta1.Coin fee_limit = 6;
 * @return {!Array<!proto.cosmos.base.v1beta1.Coin>}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getFeeLimitList = function() {
  return /** @type{!Array<!proto.cosmos.base.v1beta1.Coin>} */ (
    jspb.Message.getRepeatedWrapperField(this, cosmos_base_v1beta1_coin_pb.Coin, 6));
};


/**
 * @param {!Array<!proto.cosmos.base.v1beta1.Coin>} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
*/
proto.oracle.v1.OracleRequestPacketData.prototype.setFeeLimitList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cosmos.base.v1beta1.Coin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.base.v1beta1.Coin}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.addFeeLimit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cosmos.base.v1beta1.Coin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.clearFeeLimitList = function() {
  return this.setFeeLimitList([]);
};


/**
 * optional uint64 prepare_gas = 7;
 * @return {number}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getPrepareGas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.setPrepareGas = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 execute_gas = 8;
 * @return {number}
 */
proto.oracle.v1.OracleRequestPacketData.prototype.getExecuteGas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleRequestPacketData} returns this
 */
proto.oracle.v1.OracleRequestPacketData.prototype.setExecuteGas = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.OracleRequestPacketAcknowledgement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.OracleRequestPacketAcknowledgement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.OracleRequestPacketAcknowledgement}
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.OracleRequestPacketAcknowledgement;
  return proto.oracle.v1.OracleRequestPacketAcknowledgement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.OracleRequestPacketAcknowledgement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.OracleRequestPacketAcknowledgement}
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.OracleRequestPacketAcknowledgement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.OracleRequestPacketAcknowledgement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 request_id = 1;
 * @return {number}
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleRequestPacketAcknowledgement} returns this
 */
proto.oracle.v1.OracleRequestPacketAcknowledgement.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.OracleResponsePacketData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.OracleResponsePacketData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleResponsePacketData.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ansCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    requestTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    resolveTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    resolveStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    result: msg.getResult_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.OracleResponsePacketData}
 */
proto.oracle.v1.OracleResponsePacketData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.OracleResponsePacketData;
  return proto.oracle.v1.OracleResponsePacketData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.OracleResponsePacketData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.OracleResponsePacketData}
 */
proto.oracle.v1.OracleResponsePacketData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAnsCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolveTime(value);
      break;
    case 6:
      var value = /** @type {!proto.oracle.v1.ResolveStatus} */ (reader.readEnum());
      msg.setResolveStatus(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.OracleResponsePacketData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.OracleResponsePacketData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.OracleResponsePacketData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getAnsCount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getRequestTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getResolveTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getResolveStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getResult_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.OracleResponsePacketData} returns this
 */
proto.oracle.v1.OracleResponsePacketData.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 request_id = 2;
 * @return {number}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleResponsePacketData} returns this
 */
proto.oracle.v1.OracleResponsePacketData.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 ans_count = 3;
 * @return {number}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getAnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleResponsePacketData} returns this
 */
proto.oracle.v1.OracleResponsePacketData.prototype.setAnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 request_time = 4;
 * @return {number}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getRequestTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleResponsePacketData} returns this
 */
proto.oracle.v1.OracleResponsePacketData.prototype.setRequestTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 resolve_time = 5;
 * @return {number}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getResolveTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.OracleResponsePacketData} returns this
 */
proto.oracle.v1.OracleResponsePacketData.prototype.setResolveTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ResolveStatus resolve_status = 6;
 * @return {!proto.oracle.v1.ResolveStatus}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getResolveStatus = function() {
  return /** @type {!proto.oracle.v1.ResolveStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.oracle.v1.ResolveStatus} value
 * @return {!proto.oracle.v1.OracleResponsePacketData} returns this
 */
proto.oracle.v1.OracleResponsePacketData.prototype.setResolveStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bytes result = 7;
 * @return {!(string|Uint8Array)}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getResult = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes result = 7;
 * This is a type-conversion wrapper around `getResult()`
 * @return {string}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getResult_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getResult()));
};


/**
 * optional bytes result = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getResult()`
 * @return {!Uint8Array}
 */
proto.oracle.v1.OracleResponsePacketData.prototype.getResult_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getResult()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.oracle.v1.OracleResponsePacketData} returns this
 */
proto.oracle.v1.OracleResponsePacketData.prototype.setResult = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oracleScriptId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    calldata: msg.getCalldata_asB64(),
    askCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    requestId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ansCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    requestTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    resolveTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    resolveStatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    result: msg.getResult_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.Result}
 */
proto.oracle.v1.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.Result;
  return proto.oracle.v1.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.Result}
 */
proto.oracle.v1.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOracleScriptId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCalldata(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAskCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAnsCount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolveTime(value);
      break;
    case 10:
      var value = /** @type {!proto.oracle.v1.ResolveStatus} */ (reader.readEnum());
      msg.setResolveStatus(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOracleScriptId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCalldata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAskCount();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getMinCount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getAnsCount();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getRequestTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getResolveTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getResolveStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getResult_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.oracle.v1.Result.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 oracle_script_id = 2;
 * @return {number}
 */
proto.oracle.v1.Result.prototype.getOracleScriptId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setOracleScriptId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes calldata = 3;
 * @return {!(string|Uint8Array)}
 */
proto.oracle.v1.Result.prototype.getCalldata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes calldata = 3;
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {string}
 */
proto.oracle.v1.Result.prototype.getCalldata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCalldata()));
};


/**
 * optional bytes calldata = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCalldata()`
 * @return {!Uint8Array}
 */
proto.oracle.v1.Result.prototype.getCalldata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCalldata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setCalldata = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional uint64 ask_count = 4;
 * @return {number}
 */
proto.oracle.v1.Result.prototype.getAskCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setAskCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 min_count = 5;
 * @return {number}
 */
proto.oracle.v1.Result.prototype.getMinCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setMinCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 request_id = 6;
 * @return {number}
 */
proto.oracle.v1.Result.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 ans_count = 7;
 * @return {number}
 */
proto.oracle.v1.Result.prototype.getAnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setAnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 request_time = 8;
 * @return {number}
 */
proto.oracle.v1.Result.prototype.getRequestTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setRequestTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 resolve_time = 9;
 * @return {number}
 */
proto.oracle.v1.Result.prototype.getResolveTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setResolveTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional ResolveStatus resolve_status = 10;
 * @return {!proto.oracle.v1.ResolveStatus}
 */
proto.oracle.v1.Result.prototype.getResolveStatus = function() {
  return /** @type {!proto.oracle.v1.ResolveStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.oracle.v1.ResolveStatus} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setResolveStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bytes result = 11;
 * @return {!(string|Uint8Array)}
 */
proto.oracle.v1.Result.prototype.getResult = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes result = 11;
 * This is a type-conversion wrapper around `getResult()`
 * @return {string}
 */
proto.oracle.v1.Result.prototype.getResult_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getResult()));
};


/**
 * optional bytes result = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getResult()`
 * @return {!Uint8Array}
 */
proto.oracle.v1.Result.prototype.getResult_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getResult()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.oracle.v1.Result} returns this
 */
proto.oracle.v1.Result.prototype.setResult = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.ValidatorStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.ValidatorStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.ValidatorStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.ValidatorStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    since: (f = msg.getSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.ValidatorStatus}
 */
proto.oracle.v1.ValidatorStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.ValidatorStatus;
  return proto.oracle.v1.ValidatorStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.ValidatorStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.ValidatorStatus}
 */
proto.oracle.v1.ValidatorStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSince(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.ValidatorStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.ValidatorStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.ValidatorStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.ValidatorStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSince();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_active = 1;
 * @return {boolean}
 */
proto.oracle.v1.ValidatorStatus.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oracle.v1.ValidatorStatus} returns this
 */
proto.oracle.v1.ValidatorStatus.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp since = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.oracle.v1.ValidatorStatus.prototype.getSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.oracle.v1.ValidatorStatus} returns this
*/
proto.oracle.v1.ValidatorStatus.prototype.setSince = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oracle.v1.ValidatorStatus} returns this
 */
proto.oracle.v1.ValidatorStatus.prototype.clearSince = function() {
  return this.setSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oracle.v1.ValidatorStatus.prototype.hasSince = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.ActiveValidator.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.ActiveValidator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.ActiveValidator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.ActiveValidator.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    power: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.ActiveValidator}
 */
proto.oracle.v1.ActiveValidator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.ActiveValidator;
  return proto.oracle.v1.ActiveValidator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.ActiveValidator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.ActiveValidator}
 */
proto.oracle.v1.ActiveValidator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPower(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.ActiveValidator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.ActiveValidator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.ActiveValidator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.ActiveValidator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPower();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.oracle.v1.ActiveValidator.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.ActiveValidator} returns this
 */
proto.oracle.v1.ActiveValidator.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 power = 2;
 * @return {number}
 */
proto.oracle.v1.ActiveValidator.prototype.getPower = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.ActiveValidator} returns this
 */
proto.oracle.v1.ActiveValidator.prototype.setPower = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxRawRequestCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxAskCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxCalldataSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxReportDataSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expirationBlockCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    baseOwasmGas: jspb.Message.getFieldWithDefault(msg, 6, 0),
    perValidatorRequestGas: jspb.Message.getFieldWithDefault(msg, 7, 0),
    samplingTryCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    oracleRewardPercentage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    inactivePenaltyDuration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    ibcRequestEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.Params}
 */
proto.oracle.v1.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.Params;
  return proto.oracle.v1.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.Params}
 */
proto.oracle.v1.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxRawRequestCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxAskCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxCalldataSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxReportDataSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExpirationBlockCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBaseOwasmGas(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPerValidatorRequestGas(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSamplingTryCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOracleRewardPercentage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setInactivePenaltyDuration(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIbcRequestEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxRawRequestCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getMaxAskCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMaxCalldataSize();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getMaxReportDataSize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getExpirationBlockCount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getBaseOwasmGas();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getPerValidatorRequestGas();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getSamplingTryCount();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getOracleRewardPercentage();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getInactivePenaltyDuration();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getIbcRequestEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional uint64 max_raw_request_count = 1;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getMaxRawRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setMaxRawRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 max_ask_count = 2;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getMaxAskCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setMaxAskCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 max_calldata_size = 3;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getMaxCalldataSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setMaxCalldataSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 max_report_data_size = 4;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getMaxReportDataSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setMaxReportDataSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 expiration_block_count = 5;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getExpirationBlockCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setExpirationBlockCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 base_owasm_gas = 6;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getBaseOwasmGas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setBaseOwasmGas = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 per_validator_request_gas = 7;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getPerValidatorRequestGas = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setPerValidatorRequestGas = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 sampling_try_count = 8;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getSamplingTryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setSamplingTryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 oracle_reward_percentage = 9;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getOracleRewardPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setOracleRewardPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 inactive_penalty_duration = 10;
 * @return {number}
 */
proto.oracle.v1.Params.prototype.getInactivePenaltyDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setInactivePenaltyDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool ibc_request_enabled = 11;
 * @return {boolean}
 */
proto.oracle.v1.Params.prototype.getIbcRequestEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oracle.v1.Params} returns this
 */
proto.oracle.v1.Params.prototype.setIbcRequestEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oracle.v1.PendingResolveList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.PendingResolveList.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.PendingResolveList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.PendingResolveList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.PendingResolveList.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.PendingResolveList}
 */
proto.oracle.v1.PendingResolveList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.PendingResolveList;
  return proto.oracle.v1.PendingResolveList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.PendingResolveList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.PendingResolveList}
 */
proto.oracle.v1.PendingResolveList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequestIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.PendingResolveList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.PendingResolveList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.PendingResolveList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.PendingResolveList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
};


/**
 * repeated uint64 request_ids = 1;
 * @return {!Array<number>}
 */
proto.oracle.v1.PendingResolveList.prototype.getRequestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.oracle.v1.PendingResolveList} returns this
 */
proto.oracle.v1.PendingResolveList.prototype.setRequestIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.oracle.v1.PendingResolveList} returns this
 */
proto.oracle.v1.PendingResolveList.prototype.addRequestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oracle.v1.PendingResolveList} returns this
 */
proto.oracle.v1.PendingResolveList.prototype.clearRequestIdsList = function() {
  return this.setRequestIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.IBCChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.IBCChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.IBCChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.IBCChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    portId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.IBCChannel}
 */
proto.oracle.v1.IBCChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.IBCChannel;
  return proto.oracle.v1.IBCChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.IBCChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.IBCChannel}
 */
proto.oracle.v1.IBCChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.IBCChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.IBCChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.IBCChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.IBCChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string port_id = 1;
 * @return {string}
 */
proto.oracle.v1.IBCChannel.prototype.getPortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.IBCChannel} returns this
 */
proto.oracle.v1.IBCChannel.prototype.setPortId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel_id = 2;
 * @return {string}
 */
proto.oracle.v1.IBCChannel.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.IBCChannel} returns this
 */
proto.oracle.v1.IBCChannel.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.RequestVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.RequestVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.RequestVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.RequestVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    externalId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.RequestVerification}
 */
proto.oracle.v1.RequestVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.RequestVerification;
  return proto.oracle.v1.RequestVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.RequestVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.RequestVerification}
 */
proto.oracle.v1.RequestVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidator(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.RequestVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.RequestVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.RequestVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.RequestVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getExternalId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional string chain_id = 1;
 * @return {string}
 */
proto.oracle.v1.RequestVerification.prototype.getChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.RequestVerification} returns this
 */
proto.oracle.v1.RequestVerification.prototype.setChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string validator = 2;
 * @return {string}
 */
proto.oracle.v1.RequestVerification.prototype.getValidator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.RequestVerification} returns this
 */
proto.oracle.v1.RequestVerification.prototype.setValidator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 request_id = 3;
 * @return {number}
 */
proto.oracle.v1.RequestVerification.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.RequestVerification} returns this
 */
proto.oracle.v1.RequestVerification.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 external_id = 4;
 * @return {number}
 */
proto.oracle.v1.RequestVerification.prototype.getExternalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.RequestVerification} returns this
 */
proto.oracle.v1.RequestVerification.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oracle.v1.PriceResult.prototype.toObject = function(opt_includeInstance) {
  return proto.oracle.v1.PriceResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oracle.v1.PriceResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.PriceResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    multiplier: jspb.Message.getFieldWithDefault(msg, 2, 0),
    px: jspb.Message.getFieldWithDefault(msg, 3, 0),
    requestId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    resolveTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oracle.v1.PriceResult}
 */
proto.oracle.v1.PriceResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oracle.v1.PriceResult;
  return proto.oracle.v1.PriceResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oracle.v1.PriceResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oracle.v1.PriceResult}
 */
proto.oracle.v1.PriceResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMultiplier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResolveTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oracle.v1.PriceResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oracle.v1.PriceResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oracle.v1.PriceResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oracle.v1.PriceResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPx();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getResolveTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.oracle.v1.PriceResult.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oracle.v1.PriceResult} returns this
 */
proto.oracle.v1.PriceResult.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 multiplier = 2;
 * @return {number}
 */
proto.oracle.v1.PriceResult.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.PriceResult} returns this
 */
proto.oracle.v1.PriceResult.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 px = 3;
 * @return {number}
 */
proto.oracle.v1.PriceResult.prototype.getPx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.PriceResult} returns this
 */
proto.oracle.v1.PriceResult.prototype.setPx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 request_id = 4;
 * @return {number}
 */
proto.oracle.v1.PriceResult.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.PriceResult} returns this
 */
proto.oracle.v1.PriceResult.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 resolve_time = 5;
 * @return {number}
 */
proto.oracle.v1.PriceResult.prototype.getResolveTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oracle.v1.PriceResult} returns this
 */
proto.oracle.v1.PriceResult.prototype.setResolveTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.oracle.v1.ResolveStatus = {
  RESOLVE_STATUS_OPEN_UNSPECIFIED: 0,
  RESOLVE_STATUS_SUCCESS: 1,
  RESOLVE_STATUS_FAILURE: 2,
  RESOLVE_STATUS_EXPIRED: 3
};

goog.object.extend(exports, proto.oracle.v1);
