// source: cosmos/staking/v1beta1/staking.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var cosmos_proto_cosmos_pb = require('../../../cosmos_proto/cosmos_pb.js');
goog.object.extend(proto, cosmos_proto_cosmos_pb);
var cosmos_base_v1beta1_coin_pb = require('../../../cosmos/base/v1beta1/coin_pb.js');
goog.object.extend(proto, cosmos_base_v1beta1_coin_pb);
var tendermint_types_types_pb = require('../../../tendermint/types/types_pb.js');
goog.object.extend(proto, tendermint_types_types_pb);
goog.exportSymbol('proto.cosmos.staking.v1beta1.BondStatus', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.Commission', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.CommissionRates', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.DVPair', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.DVPairs', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.DVVTriplet', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.DVVTriplets', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.Delegation', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.DelegationResponse', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.Description', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.HistoricalInfo', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.Params', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.Pool', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.Redelegation', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.RedelegationEntry', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.RedelegationEntryResponse', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.RedelegationResponse', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.UnbondingDelegation', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.UnbondingDelegationEntry', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.ValAddresses', null, global);
goog.exportSymbol('proto.cosmos.staking.v1beta1.Validator', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.HistoricalInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.staking.v1beta1.HistoricalInfo.repeatedFields_, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.HistoricalInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.HistoricalInfo.displayName = 'proto.cosmos.staking.v1beta1.HistoricalInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.CommissionRates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.CommissionRates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.CommissionRates.displayName = 'proto.cosmos.staking.v1beta1.CommissionRates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.Commission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.Commission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.Commission.displayName = 'proto.cosmos.staking.v1beta1.Commission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.Description = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.Description, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.Description.displayName = 'proto.cosmos.staking.v1beta1.Description';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.Validator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.Validator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.Validator.displayName = 'proto.cosmos.staking.v1beta1.Validator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.ValAddresses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.staking.v1beta1.ValAddresses.repeatedFields_, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.ValAddresses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.ValAddresses.displayName = 'proto.cosmos.staking.v1beta1.ValAddresses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.DVPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.DVPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.DVPair.displayName = 'proto.cosmos.staking.v1beta1.DVPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.DVPairs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.staking.v1beta1.DVPairs.repeatedFields_, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.DVPairs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.DVPairs.displayName = 'proto.cosmos.staking.v1beta1.DVPairs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.DVVTriplet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.DVVTriplet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.DVVTriplet.displayName = 'proto.cosmos.staking.v1beta1.DVVTriplet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.DVVTriplets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.staking.v1beta1.DVVTriplets.repeatedFields_, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.DVVTriplets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.DVVTriplets.displayName = 'proto.cosmos.staking.v1beta1.DVVTriplets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.Delegation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.Delegation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.Delegation.displayName = 'proto.cosmos.staking.v1beta1.Delegation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.staking.v1beta1.UnbondingDelegation.repeatedFields_, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.UnbondingDelegation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.UnbondingDelegation.displayName = 'proto.cosmos.staking.v1beta1.UnbondingDelegation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.UnbondingDelegationEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.displayName = 'proto.cosmos.staking.v1beta1.UnbondingDelegationEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.RedelegationEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.RedelegationEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.RedelegationEntry.displayName = 'proto.cosmos.staking.v1beta1.RedelegationEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.Redelegation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.staking.v1beta1.Redelegation.repeatedFields_, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.Redelegation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.Redelegation.displayName = 'proto.cosmos.staking.v1beta1.Redelegation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.Params.displayName = 'proto.cosmos.staking.v1beta1.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.DelegationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.DelegationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.DelegationResponse.displayName = 'proto.cosmos.staking.v1beta1.DelegationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.RedelegationEntryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.RedelegationEntryResponse.displayName = 'proto.cosmos.staking.v1beta1.RedelegationEntryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.RedelegationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cosmos.staking.v1beta1.RedelegationResponse.repeatedFields_, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.RedelegationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.RedelegationResponse.displayName = 'proto.cosmos.staking.v1beta1.RedelegationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cosmos.staking.v1beta1.Pool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cosmos.staking.v1beta1.Pool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cosmos.staking.v1beta1.Pool.displayName = 'proto.cosmos.staking.v1beta1.Pool';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.HistoricalInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.HistoricalInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && tendermint_types_types_pb.Header.toObject(includeInstance, f),
    valsetList: jspb.Message.toObjectList(msg.getValsetList(),
    proto.cosmos.staking.v1beta1.Validator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.HistoricalInfo}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.HistoricalInfo;
  return proto.cosmos.staking.v1beta1.HistoricalInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.HistoricalInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.HistoricalInfo}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new tendermint_types_types_pb.Header;
      reader.readMessage(value,tendermint_types_types_pb.Header.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.cosmos.staking.v1beta1.Validator;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.Validator.deserializeBinaryFromReader);
      msg.addValset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.HistoricalInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.HistoricalInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      tendermint_types_types_pb.Header.serializeBinaryToWriter
    );
  }
  f = message.getValsetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cosmos.staking.v1beta1.Validator.serializeBinaryToWriter
    );
  }
};


/**
 * optional tendermint.types.Header header = 1;
 * @return {?proto.tendermint.types.Header}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.getHeader = function() {
  return /** @type{?proto.tendermint.types.Header} */ (
    jspb.Message.getWrapperField(this, tendermint_types_types_pb.Header, 1));
};


/**
 * @param {?proto.tendermint.types.Header|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.HistoricalInfo} returns this
*/
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.HistoricalInfo} returns this
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Validator valset = 2;
 * @return {!Array<!proto.cosmos.staking.v1beta1.Validator>}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.getValsetList = function() {
  return /** @type{!Array<!proto.cosmos.staking.v1beta1.Validator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cosmos.staking.v1beta1.Validator, 2));
};


/**
 * @param {!Array<!proto.cosmos.staking.v1beta1.Validator>} value
 * @return {!proto.cosmos.staking.v1beta1.HistoricalInfo} returns this
*/
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.setValsetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cosmos.staking.v1beta1.Validator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.staking.v1beta1.Validator}
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.addValset = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cosmos.staking.v1beta1.Validator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.staking.v1beta1.HistoricalInfo} returns this
 */
proto.cosmos.staking.v1beta1.HistoricalInfo.prototype.clearValsetList = function() {
  return this.setValsetList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.CommissionRates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.CommissionRates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.CommissionRates.toObject = function(includeInstance, msg) {
  var f, obj = {
    rate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxRate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxChangeRate: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.CommissionRates}
 */
proto.cosmos.staking.v1beta1.CommissionRates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.CommissionRates;
  return proto.cosmos.staking.v1beta1.CommissionRates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.CommissionRates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.CommissionRates}
 */
proto.cosmos.staking.v1beta1.CommissionRates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxRate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxChangeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.CommissionRates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.CommissionRates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.CommissionRates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxRate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxChangeRate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string rate = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.CommissionRates} returns this
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string max_rate = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.getMaxRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.CommissionRates} returns this
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.setMaxRate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string max_change_rate = 3;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.getMaxChangeRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.CommissionRates} returns this
 */
proto.cosmos.staking.v1beta1.CommissionRates.prototype.setMaxChangeRate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.Commission.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.Commission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.Commission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Commission.toObject = function(includeInstance, msg) {
  var f, obj = {
    commissionRates: (f = msg.getCommissionRates()) && proto.cosmos.staking.v1beta1.CommissionRates.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.Commission}
 */
proto.cosmos.staking.v1beta1.Commission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.Commission;
  return proto.cosmos.staking.v1beta1.Commission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.Commission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.Commission}
 */
proto.cosmos.staking.v1beta1.Commission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cosmos.staking.v1beta1.CommissionRates;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.CommissionRates.deserializeBinaryFromReader);
      msg.setCommissionRates(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.Commission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.Commission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.Commission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Commission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommissionRates();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cosmos.staking.v1beta1.CommissionRates.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommissionRates commission_rates = 1;
 * @return {?proto.cosmos.staking.v1beta1.CommissionRates}
 */
proto.cosmos.staking.v1beta1.Commission.prototype.getCommissionRates = function() {
  return /** @type{?proto.cosmos.staking.v1beta1.CommissionRates} */ (
    jspb.Message.getWrapperField(this, proto.cosmos.staking.v1beta1.CommissionRates, 1));
};


/**
 * @param {?proto.cosmos.staking.v1beta1.CommissionRates|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.Commission} returns this
*/
proto.cosmos.staking.v1beta1.Commission.prototype.setCommissionRates = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.Commission} returns this
 */
proto.cosmos.staking.v1beta1.Commission.prototype.clearCommissionRates = function() {
  return this.setCommissionRates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Commission.prototype.hasCommissionRates = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.staking.v1beta1.Commission.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.Commission} returns this
*/
proto.cosmos.staking.v1beta1.Commission.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.Commission} returns this
 */
proto.cosmos.staking.v1beta1.Commission.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Commission.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.Description.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.Description.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.Description} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Description.toObject = function(includeInstance, msg) {
  var f, obj = {
    moniker: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 2, ""),
    website: jspb.Message.getFieldWithDefault(msg, 3, ""),
    securityContact: jspb.Message.getFieldWithDefault(msg, 4, ""),
    details: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.Description}
 */
proto.cosmos.staking.v1beta1.Description.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.Description;
  return proto.cosmos.staking.v1beta1.Description.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.Description} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.Description}
 */
proto.cosmos.staking.v1beta1.Description.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoniker(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityContact(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.Description.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.Description.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.Description} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Description.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoniker();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecurityContact();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string moniker = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Description.prototype.getMoniker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Description} returns this
 */
proto.cosmos.staking.v1beta1.Description.prototype.setMoniker = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identity = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Description.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Description} returns this
 */
proto.cosmos.staking.v1beta1.Description.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string website = 3;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Description.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Description} returns this
 */
proto.cosmos.staking.v1beta1.Description.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string security_contact = 4;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Description.prototype.getSecurityContact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Description} returns this
 */
proto.cosmos.staking.v1beta1.Description.prototype.setSecurityContact = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string details = 5;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Description.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Description} returns this
 */
proto.cosmos.staking.v1beta1.Description.prototype.setDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.Validator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.Validator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Validator.toObject = function(includeInstance, msg) {
  var f, obj = {
    operatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    consensusPubkey: (f = msg.getConsensusPubkey()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    jailed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tokens: jspb.Message.getFieldWithDefault(msg, 5, ""),
    delegatorShares: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: (f = msg.getDescription()) && proto.cosmos.staking.v1beta1.Description.toObject(includeInstance, f),
    unbondingHeight: jspb.Message.getFieldWithDefault(msg, 8, 0),
    unbondingTime: (f = msg.getUnbondingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    commission: (f = msg.getCommission()) && proto.cosmos.staking.v1beta1.Commission.toObject(includeInstance, f),
    minSelfDelegation: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.Validator}
 */
proto.cosmos.staking.v1beta1.Validator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.Validator;
  return proto.cosmos.staking.v1beta1.Validator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.Validator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.Validator}
 */
proto.cosmos.staking.v1beta1.Validator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorAddress(value);
      break;
    case 2:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setConsensusPubkey(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setJailed(value);
      break;
    case 4:
      var value = /** @type {!proto.cosmos.staking.v1beta1.BondStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokens(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegatorShares(value);
      break;
    case 7:
      var value = new proto.cosmos.staking.v1beta1.Description;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.Description.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnbondingHeight(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUnbondingTime(value);
      break;
    case 10:
      var value = new proto.cosmos.staking.v1beta1.Commission;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.Commission.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinSelfDelegation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.Validator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.Validator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Validator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperatorAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConsensusPubkey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getJailed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTokens();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDelegatorShares();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cosmos.staking.v1beta1.Description.serializeBinaryToWriter
    );
  }
  f = message.getUnbondingHeight();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUnbondingTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.cosmos.staking.v1beta1.Commission.serializeBinaryToWriter
    );
  }
  f = message.getMinSelfDelegation();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string operator_address = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getOperatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.setOperatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Any consensus_pubkey = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getConsensusPubkey = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
*/
proto.cosmos.staking.v1beta1.Validator.prototype.setConsensusPubkey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.clearConsensusPubkey = function() {
  return this.setConsensusPubkey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.hasConsensusPubkey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool jailed = 3;
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getJailed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.setJailed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional BondStatus status = 4;
 * @return {!proto.cosmos.staking.v1beta1.BondStatus}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getStatus = function() {
  return /** @type {!proto.cosmos.staking.v1beta1.BondStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.cosmos.staking.v1beta1.BondStatus} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string tokens = 5;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getTokens = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.setTokens = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string delegator_shares = 6;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getDelegatorShares = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.setDelegatorShares = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Description description = 7;
 * @return {?proto.cosmos.staking.v1beta1.Description}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getDescription = function() {
  return /** @type{?proto.cosmos.staking.v1beta1.Description} */ (
    jspb.Message.getWrapperField(this, proto.cosmos.staking.v1beta1.Description, 7));
};


/**
 * @param {?proto.cosmos.staking.v1beta1.Description|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
*/
proto.cosmos.staking.v1beta1.Validator.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 unbonding_height = 8;
 * @return {number}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getUnbondingHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.setUnbondingHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp unbonding_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getUnbondingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
*/
proto.cosmos.staking.v1beta1.Validator.prototype.setUnbondingTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.clearUnbondingTime = function() {
  return this.setUnbondingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.hasUnbondingTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Commission commission = 10;
 * @return {?proto.cosmos.staking.v1beta1.Commission}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getCommission = function() {
  return /** @type{?proto.cosmos.staking.v1beta1.Commission} */ (
    jspb.Message.getWrapperField(this, proto.cosmos.staking.v1beta1.Commission, 10));
};


/**
 * @param {?proto.cosmos.staking.v1beta1.Commission|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
*/
proto.cosmos.staking.v1beta1.Validator.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string min_self_delegation = 11;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Validator.prototype.getMinSelfDelegation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Validator} returns this
 */
proto.cosmos.staking.v1beta1.Validator.prototype.setMinSelfDelegation = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.staking.v1beta1.ValAddresses.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.ValAddresses.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.ValAddresses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.ValAddresses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.ValAddresses.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.ValAddresses}
 */
proto.cosmos.staking.v1beta1.ValAddresses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.ValAddresses;
  return proto.cosmos.staking.v1beta1.ValAddresses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.ValAddresses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.ValAddresses}
 */
proto.cosmos.staking.v1beta1.ValAddresses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddresses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.ValAddresses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.ValAddresses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.ValAddresses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.ValAddresses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string addresses = 1;
 * @return {!Array<string>}
 */
proto.cosmos.staking.v1beta1.ValAddresses.prototype.getAddressesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cosmos.staking.v1beta1.ValAddresses} returns this
 */
proto.cosmos.staking.v1beta1.ValAddresses.prototype.setAddressesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cosmos.staking.v1beta1.ValAddresses} returns this
 */
proto.cosmos.staking.v1beta1.ValAddresses.prototype.addAddresses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.staking.v1beta1.ValAddresses} returns this
 */
proto.cosmos.staking.v1beta1.ValAddresses.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.DVPair.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.DVPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.DVPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    delegatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validatorAddress: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.DVPair}
 */
proto.cosmos.staking.v1beta1.DVPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.DVPair;
  return proto.cosmos.staking.v1beta1.DVPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.DVPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.DVPair}
 */
proto.cosmos.staking.v1beta1.DVPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegatorAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.DVPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.DVPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.DVPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelegatorAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidatorAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string delegator_address = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.DVPair.prototype.getDelegatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.DVPair} returns this
 */
proto.cosmos.staking.v1beta1.DVPair.prototype.setDelegatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string validator_address = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.DVPair.prototype.getValidatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.DVPair} returns this
 */
proto.cosmos.staking.v1beta1.DVPair.prototype.setValidatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.staking.v1beta1.DVPairs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.DVPairs.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.DVPairs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.DVPairs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVPairs.toObject = function(includeInstance, msg) {
  var f, obj = {
    pairsList: jspb.Message.toObjectList(msg.getPairsList(),
    proto.cosmos.staking.v1beta1.DVPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.DVPairs}
 */
proto.cosmos.staking.v1beta1.DVPairs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.DVPairs;
  return proto.cosmos.staking.v1beta1.DVPairs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.DVPairs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.DVPairs}
 */
proto.cosmos.staking.v1beta1.DVPairs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cosmos.staking.v1beta1.DVPair;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.DVPair.deserializeBinaryFromReader);
      msg.addPairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.DVPairs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.DVPairs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.DVPairs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVPairs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cosmos.staking.v1beta1.DVPair.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DVPair pairs = 1;
 * @return {!Array<!proto.cosmos.staking.v1beta1.DVPair>}
 */
proto.cosmos.staking.v1beta1.DVPairs.prototype.getPairsList = function() {
  return /** @type{!Array<!proto.cosmos.staking.v1beta1.DVPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cosmos.staking.v1beta1.DVPair, 1));
};


/**
 * @param {!Array<!proto.cosmos.staking.v1beta1.DVPair>} value
 * @return {!proto.cosmos.staking.v1beta1.DVPairs} returns this
*/
proto.cosmos.staking.v1beta1.DVPairs.prototype.setPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.staking.v1beta1.DVPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.staking.v1beta1.DVPair}
 */
proto.cosmos.staking.v1beta1.DVPairs.prototype.addPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.staking.v1beta1.DVPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.staking.v1beta1.DVPairs} returns this
 */
proto.cosmos.staking.v1beta1.DVPairs.prototype.clearPairsList = function() {
  return this.setPairsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.DVVTriplet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.DVVTriplet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVVTriplet.toObject = function(includeInstance, msg) {
  var f, obj = {
    delegatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validatorSrcAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    validatorDstAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplet}
 */
proto.cosmos.staking.v1beta1.DVVTriplet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.DVVTriplet;
  return proto.cosmos.staking.v1beta1.DVVTriplet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.DVVTriplet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplet}
 */
proto.cosmos.staking.v1beta1.DVVTriplet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegatorAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorSrcAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorDstAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.DVVTriplet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.DVVTriplet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVVTriplet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelegatorAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidatorSrcAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValidatorDstAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string delegator_address = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.getDelegatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplet} returns this
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.setDelegatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string validator_src_address = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.getValidatorSrcAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplet} returns this
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.setValidatorSrcAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string validator_dst_address = 3;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.getValidatorDstAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplet} returns this
 */
proto.cosmos.staking.v1beta1.DVVTriplet.prototype.setValidatorDstAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.staking.v1beta1.DVVTriplets.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.DVVTriplets.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.DVVTriplets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.DVVTriplets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVVTriplets.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripletsList: jspb.Message.toObjectList(msg.getTripletsList(),
    proto.cosmos.staking.v1beta1.DVVTriplet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplets}
 */
proto.cosmos.staking.v1beta1.DVVTriplets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.DVVTriplets;
  return proto.cosmos.staking.v1beta1.DVVTriplets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.DVVTriplets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplets}
 */
proto.cosmos.staking.v1beta1.DVVTriplets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cosmos.staking.v1beta1.DVVTriplet;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.DVVTriplet.deserializeBinaryFromReader);
      msg.addTriplets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.DVVTriplets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.DVVTriplets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.DVVTriplets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DVVTriplets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cosmos.staking.v1beta1.DVVTriplet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DVVTriplet triplets = 1;
 * @return {!Array<!proto.cosmos.staking.v1beta1.DVVTriplet>}
 */
proto.cosmos.staking.v1beta1.DVVTriplets.prototype.getTripletsList = function() {
  return /** @type{!Array<!proto.cosmos.staking.v1beta1.DVVTriplet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cosmos.staking.v1beta1.DVVTriplet, 1));
};


/**
 * @param {!Array<!proto.cosmos.staking.v1beta1.DVVTriplet>} value
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplets} returns this
*/
proto.cosmos.staking.v1beta1.DVVTriplets.prototype.setTripletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cosmos.staking.v1beta1.DVVTriplet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplet}
 */
proto.cosmos.staking.v1beta1.DVVTriplets.prototype.addTriplets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cosmos.staking.v1beta1.DVVTriplet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.staking.v1beta1.DVVTriplets} returns this
 */
proto.cosmos.staking.v1beta1.DVVTriplets.prototype.clearTripletsList = function() {
  return this.setTripletsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.Delegation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.Delegation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Delegation.toObject = function(includeInstance, msg) {
  var f, obj = {
    delegatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validatorAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shares: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.Delegation}
 */
proto.cosmos.staking.v1beta1.Delegation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.Delegation;
  return proto.cosmos.staking.v1beta1.Delegation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.Delegation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.Delegation}
 */
proto.cosmos.staking.v1beta1.Delegation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegatorAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShares(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.Delegation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.Delegation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Delegation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelegatorAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidatorAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShares();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string delegator_address = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.getDelegatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Delegation} returns this
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.setDelegatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string validator_address = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.getValidatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Delegation} returns this
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.setValidatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shares = 3;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.getShares = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Delegation} returns this
 */
proto.cosmos.staking.v1beta1.Delegation.prototype.setShares = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.UnbondingDelegation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.UnbondingDelegation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.toObject = function(includeInstance, msg) {
  var f, obj = {
    delegatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validatorAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegation}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.UnbondingDelegation;
  return proto.cosmos.staking.v1beta1.UnbondingDelegation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.UnbondingDelegation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegation}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegatorAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorAddress(value);
      break;
    case 3:
      var value = new proto.cosmos.staking.v1beta1.UnbondingDelegationEntry;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.UnbondingDelegation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.UnbondingDelegation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelegatorAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidatorAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string delegator_address = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.getDelegatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegation} returns this
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.setDelegatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string validator_address = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.getValidatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegation} returns this
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.setValidatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UnbondingDelegationEntry entries = 3;
 * @return {!Array<!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry>}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cosmos.staking.v1beta1.UnbondingDelegationEntry, 3));
};


/**
 * @param {!Array<!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry>} value
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegation} returns this
*/
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cosmos.staking.v1beta1.UnbondingDelegationEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegation} returns this
 */
proto.cosmos.staking.v1beta1.UnbondingDelegation.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    creationHeight: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    initialBalance: jspb.Message.getFieldWithDefault(msg, 3, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.UnbondingDelegationEntry;
  return proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationHeight(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialBalance(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreationHeight();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInitialBalance();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 creation_height = 1;
 * @return {number}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.getCreationHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.setCreationHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp completion_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} returns this
*/
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string initial_balance = 3;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.getInitialBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.setInitialBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string balance = 4;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.UnbondingDelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.UnbondingDelegationEntry.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.RedelegationEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    creationHeight: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    initialBalance: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sharesDst: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.RedelegationEntry;
  return proto.cosmos.staking.v1beta1.RedelegationEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationHeight(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialBalance(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharesDst(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.RedelegationEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreationHeight();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInitialBalance();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSharesDst();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 creation_height = 1;
 * @return {number}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.getCreationHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.setCreationHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp completion_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry} returns this
*/
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string initial_balance = 3;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.getInitialBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.setInitialBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string shares_dst = 4;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.getSharesDst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationEntry.prototype.setSharesDst = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.staking.v1beta1.Redelegation.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.Redelegation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.Redelegation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Redelegation.toObject = function(includeInstance, msg) {
  var f, obj = {
    delegatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validatorSrcAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    validatorDstAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.cosmos.staking.v1beta1.RedelegationEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.Redelegation}
 */
proto.cosmos.staking.v1beta1.Redelegation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.Redelegation;
  return proto.cosmos.staking.v1beta1.Redelegation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.Redelegation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.Redelegation}
 */
proto.cosmos.staking.v1beta1.Redelegation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegatorAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorSrcAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidatorDstAddress(value);
      break;
    case 4:
      var value = new proto.cosmos.staking.v1beta1.RedelegationEntry;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.RedelegationEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.Redelegation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.Redelegation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Redelegation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelegatorAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidatorSrcAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValidatorDstAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cosmos.staking.v1beta1.RedelegationEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string delegator_address = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.getDelegatorAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Redelegation} returns this
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.setDelegatorAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string validator_src_address = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.getValidatorSrcAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Redelegation} returns this
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.setValidatorSrcAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string validator_dst_address = 3;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.getValidatorDstAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Redelegation} returns this
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.setValidatorDstAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated RedelegationEntry entries = 4;
 * @return {!Array<!proto.cosmos.staking.v1beta1.RedelegationEntry>}
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.cosmos.staking.v1beta1.RedelegationEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cosmos.staking.v1beta1.RedelegationEntry, 4));
};


/**
 * @param {!Array<!proto.cosmos.staking.v1beta1.RedelegationEntry>} value
 * @return {!proto.cosmos.staking.v1beta1.Redelegation} returns this
*/
proto.cosmos.staking.v1beta1.Redelegation.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntry}
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cosmos.staking.v1beta1.RedelegationEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.staking.v1beta1.Redelegation} returns this
 */
proto.cosmos.staking.v1beta1.Redelegation.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    unbondingTime: (f = msg.getUnbondingTime()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    maxValidators: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxEntries: jspb.Message.getFieldWithDefault(msg, 3, 0),
    historicalEntries: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bondDenom: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.Params}
 */
proto.cosmos.staking.v1beta1.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.Params;
  return proto.cosmos.staking.v1beta1.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.Params}
 */
proto.cosmos.staking.v1beta1.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setUnbondingTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxValidators(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxEntries(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHistoricalEntries(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBondDenom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnbondingTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getMaxValidators();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMaxEntries();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getHistoricalEntries();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBondDenom();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Duration unbonding_time = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.cosmos.staking.v1beta1.Params.prototype.getUnbondingTime = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.Params} returns this
*/
proto.cosmos.staking.v1beta1.Params.prototype.setUnbondingTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.Params} returns this
 */
proto.cosmos.staking.v1beta1.Params.prototype.clearUnbondingTime = function() {
  return this.setUnbondingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.Params.prototype.hasUnbondingTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 max_validators = 2;
 * @return {number}
 */
proto.cosmos.staking.v1beta1.Params.prototype.getMaxValidators = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.staking.v1beta1.Params} returns this
 */
proto.cosmos.staking.v1beta1.Params.prototype.setMaxValidators = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 max_entries = 3;
 * @return {number}
 */
proto.cosmos.staking.v1beta1.Params.prototype.getMaxEntries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.staking.v1beta1.Params} returns this
 */
proto.cosmos.staking.v1beta1.Params.prototype.setMaxEntries = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 historical_entries = 4;
 * @return {number}
 */
proto.cosmos.staking.v1beta1.Params.prototype.getHistoricalEntries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cosmos.staking.v1beta1.Params} returns this
 */
proto.cosmos.staking.v1beta1.Params.prototype.setHistoricalEntries = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string bond_denom = 5;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Params.prototype.getBondDenom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Params} returns this
 */
proto.cosmos.staking.v1beta1.Params.prototype.setBondDenom = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.DelegationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.DelegationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DelegationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    delegation: (f = msg.getDelegation()) && proto.cosmos.staking.v1beta1.Delegation.toObject(includeInstance, f),
    balance: (f = msg.getBalance()) && cosmos_base_v1beta1_coin_pb.Coin.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.DelegationResponse}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.DelegationResponse;
  return proto.cosmos.staking.v1beta1.DelegationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.DelegationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.DelegationResponse}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cosmos.staking.v1beta1.Delegation;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.Delegation.deserializeBinaryFromReader);
      msg.setDelegation(value);
      break;
    case 2:
      var value = new cosmos_base_v1beta1_coin_pb.Coin;
      reader.readMessage(value,cosmos_base_v1beta1_coin_pb.Coin.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.DelegationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.DelegationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.DelegationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelegation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cosmos.staking.v1beta1.Delegation.serializeBinaryToWriter
    );
  }
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      cosmos_base_v1beta1_coin_pb.Coin.serializeBinaryToWriter
    );
  }
};


/**
 * optional Delegation delegation = 1;
 * @return {?proto.cosmos.staking.v1beta1.Delegation}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.getDelegation = function() {
  return /** @type{?proto.cosmos.staking.v1beta1.Delegation} */ (
    jspb.Message.getWrapperField(this, proto.cosmos.staking.v1beta1.Delegation, 1));
};


/**
 * @param {?proto.cosmos.staking.v1beta1.Delegation|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.DelegationResponse} returns this
*/
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.setDelegation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.DelegationResponse} returns this
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.clearDelegation = function() {
  return this.setDelegation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.hasDelegation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cosmos.base.v1beta1.Coin balance = 2;
 * @return {?proto.cosmos.base.v1beta1.Coin}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.getBalance = function() {
  return /** @type{?proto.cosmos.base.v1beta1.Coin} */ (
    jspb.Message.getWrapperField(this, cosmos_base_v1beta1_coin_pb.Coin, 2));
};


/**
 * @param {?proto.cosmos.base.v1beta1.Coin|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.DelegationResponse} returns this
*/
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.DelegationResponse} returns this
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.DelegationResponse.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.RedelegationEntryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    redelegationEntry: (f = msg.getRedelegationEntry()) && proto.cosmos.staking.v1beta1.RedelegationEntry.toObject(includeInstance, f),
    balance: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse}
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.RedelegationEntryResponse;
  return proto.cosmos.staking.v1beta1.RedelegationEntryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse}
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cosmos.staking.v1beta1.RedelegationEntry;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.RedelegationEntry.deserializeBinaryFromReader);
      msg.setRedelegationEntry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.RedelegationEntryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedelegationEntry();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cosmos.staking.v1beta1.RedelegationEntry.serializeBinaryToWriter
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional RedelegationEntry redelegation_entry = 1;
 * @return {?proto.cosmos.staking.v1beta1.RedelegationEntry}
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.getRedelegationEntry = function() {
  return /** @type{?proto.cosmos.staking.v1beta1.RedelegationEntry} */ (
    jspb.Message.getWrapperField(this, proto.cosmos.staking.v1beta1.RedelegationEntry, 1));
};


/**
 * @param {?proto.cosmos.staking.v1beta1.RedelegationEntry|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse} returns this
*/
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.setRedelegationEntry = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.clearRedelegationEntry = function() {
  return this.setRedelegationEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.hasRedelegationEntry = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string balance = 4;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationEntryResponse.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.RedelegationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.RedelegationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    redelegation: (f = msg.getRedelegation()) && proto.cosmos.staking.v1beta1.Redelegation.toObject(includeInstance, f),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.cosmos.staking.v1beta1.RedelegationEntryResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationResponse}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.RedelegationResponse;
  return proto.cosmos.staking.v1beta1.RedelegationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.RedelegationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationResponse}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cosmos.staking.v1beta1.Redelegation;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.Redelegation.deserializeBinaryFromReader);
      msg.setRedelegation(value);
      break;
    case 2:
      var value = new proto.cosmos.staking.v1beta1.RedelegationEntryResponse;
      reader.readMessage(value,proto.cosmos.staking.v1beta1.RedelegationEntryResponse.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.RedelegationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.RedelegationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedelegation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cosmos.staking.v1beta1.Redelegation.serializeBinaryToWriter
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cosmos.staking.v1beta1.RedelegationEntryResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional Redelegation redelegation = 1;
 * @return {?proto.cosmos.staking.v1beta1.Redelegation}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.getRedelegation = function() {
  return /** @type{?proto.cosmos.staking.v1beta1.Redelegation} */ (
    jspb.Message.getWrapperField(this, proto.cosmos.staking.v1beta1.Redelegation, 1));
};


/**
 * @param {?proto.cosmos.staking.v1beta1.Redelegation|undefined} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationResponse} returns this
*/
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.setRedelegation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationResponse} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.clearRedelegation = function() {
  return this.setRedelegation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.hasRedelegation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RedelegationEntryResponse entries = 2;
 * @return {!Array<!proto.cosmos.staking.v1beta1.RedelegationEntryResponse>}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.cosmos.staking.v1beta1.RedelegationEntryResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cosmos.staking.v1beta1.RedelegationEntryResponse, 2));
};


/**
 * @param {!Array<!proto.cosmos.staking.v1beta1.RedelegationEntryResponse>} value
 * @return {!proto.cosmos.staking.v1beta1.RedelegationResponse} returns this
*/
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cosmos.staking.v1beta1.RedelegationEntryResponse}
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cosmos.staking.v1beta1.RedelegationEntryResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cosmos.staking.v1beta1.RedelegationResponse} returns this
 */
proto.cosmos.staking.v1beta1.RedelegationResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cosmos.staking.v1beta1.Pool.prototype.toObject = function(opt_includeInstance) {
  return proto.cosmos.staking.v1beta1.Pool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cosmos.staking.v1beta1.Pool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Pool.toObject = function(includeInstance, msg) {
  var f, obj = {
    notBondedTokens: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bondedTokens: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cosmos.staking.v1beta1.Pool}
 */
proto.cosmos.staking.v1beta1.Pool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cosmos.staking.v1beta1.Pool;
  return proto.cosmos.staking.v1beta1.Pool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cosmos.staking.v1beta1.Pool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cosmos.staking.v1beta1.Pool}
 */
proto.cosmos.staking.v1beta1.Pool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotBondedTokens(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBondedTokens(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cosmos.staking.v1beta1.Pool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cosmos.staking.v1beta1.Pool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cosmos.staking.v1beta1.Pool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cosmos.staking.v1beta1.Pool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotBondedTokens();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBondedTokens();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string not_bonded_tokens = 1;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Pool.prototype.getNotBondedTokens = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Pool} returns this
 */
proto.cosmos.staking.v1beta1.Pool.prototype.setNotBondedTokens = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bonded_tokens = 2;
 * @return {string}
 */
proto.cosmos.staking.v1beta1.Pool.prototype.getBondedTokens = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cosmos.staking.v1beta1.Pool} returns this
 */
proto.cosmos.staking.v1beta1.Pool.prototype.setBondedTokens = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.cosmos.staking.v1beta1.BondStatus = {
  BOND_STATUS_UNSPECIFIED: 0,
  BOND_STATUS_UNBONDED: 1,
  BOND_STATUS_UNBONDING: 2,
  BOND_STATUS_BONDED: 3
};

goog.object.extend(exports, proto.cosmos.staking.v1beta1);
