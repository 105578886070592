import { Wallet } from '@bandprotocol/bandchain.js'

const isPro = true //process.env.NODE_ENV === 'production'

let web3 = null
let contractPermaLock = null
let contractERC20 = null

// TODO => .env
const contractPermaLockAddress = '0x8Ec769e84790Be3666D263313903D12Cf0bc1972'
const chainId = 'odin-mainnet-freya'
const bscChainId = 56

let odinAddress = ''

const contractPermaLockABI = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'odinAddress',
        type: 'string',
      },
    ],
    name: 'permaLock',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ODIN_TOKEN',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

const contractERC20ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
    ],
    name: 'allowance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'approve',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]

const chainInterface = {
  chainId: 'odin-mainnet-freya',
  chainName: 'ODIN118',
  rpc: 'http://35.241.221.154:26657',
  rest: 'http://35.241.221.154:1317',
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: 'odin',
    bech32PrefixAccPub: 'odin' + 'pub',
    bech32PrefixValAddr: 'odin' + 'valoper',
    bech32PrefixValPub: 'odin' + 'valoperpub',
    bech32PrefixConsAddr: 'odin' + 'valcons',
    bech32PrefixConsPub: 'odin' + 'valconspub',
  },
  currencies: [
    {
      coinDenom: 'odin',
      coinMinimalDenom: 'loki',
      coinDecimals: 6,
      coinGeckoId: 'odin',
    },
  ],
  feeCurrencies: [
    {
      coinDenom: 'odin',
      coinMinimalDenom: 'loki',
      coinDecimals: 6,
      coinGeckoId: 'odin',
    },
  ],
  stakeCurrency: {
    coinDenom: 'odin',
    coinMinimalDenom: 'loki',
    coinDecimals: 6,
    coinGeckoId: 'odin',
  },
  coinType: 118,
  gasPriceStep: {
    low: 0.01,
    average: 0.025,
    high: 0.03,
  },
}

let balance = 0
let isWhiteListed = false
let contractERC20Address = null

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

const updateLockBalances = async () => {
  balance = web3.utils.toBN(
    await contractERC20.methods
      .balanceOf(web3.currentProvider.selectedAddress)
      .call()
  )
  isWhiteListed = true
}

document.addEventListener('DOMContentLoaded', async function () {
  let init = setInterval(async function () {
    try {
      if (window.ethereum) {
        clearInterval(init)
        web3 = new Web3(window.ethereum)
        window.ethereum.on('accountsChanged', (accounts) => {
          console.log('Accounts changed')
          window.location.reload()
        })

        window.ethereum.on('chainChanged', (chainId) => {
          console.log('Network changed')
          window.location.reload()
        })
        let currentChainId = await web3.eth.getChainId()
        if (currentChainId !== bscChainId) {
          throw new Error('wrongEthNetwork')
        }
        await window.ethereum.enable()
        if (window.keplr) {
          try {
            await window.keplr.enable(chainId)
          } catch (e) {
            try {
              await window.keplr.experimentalSuggestChain(chainInterface)
            } catch (e) {
              document.querySelector('.geo-db__loader-title').innerHTML =
                'Initialization failed! ❌'
            }
          }
          await window.keplr.enable(chainId)
          const offlineSigner = window.getOfflineSigner(chainId)
          const accounts = await offlineSigner.getAccounts()
          odinAddress = accounts[0]['address']
          document.querySelector('#lock_address').value = odinAddress
        } else {
          throw new Error('keplr')
        }
        document.querySelector('.geo-db__loader').classList.remove('show')
      } else if (window.web3) {
        clearInterval(init)
        web3 = new Web3(window.web3.currentProvider)
        document.querySelector('.geo-db__loader').classList.remove('show')
      } else {
        // Please install metamask https://metamask.io/download
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please install <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">MetaMask</a>'
      }
      if (web3) {
        contractPermaLock = new web3.eth.Contract(
          contractPermaLockABI,
          contractPermaLockAddress
        )

        contractERC20Address = await contractPermaLock.methods
          .ODIN_TOKEN()
          .call()
        contractERC20 = new web3.eth.Contract(
          contractERC20ABI,
          contractERC20Address
        )
        await updateLockBalances()
      }
    } catch (error) {
      if (error.message === 'keplr') {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please install <a href="https://www.keplr.app/" target="_blank" rel="noopener noreferrer">Keplr</a> and create an account!'
      } else if (error.message.includes('no chain info for')) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please approve the Chain add resquest from Keplr!'
      } else if (error.message.includes('Request rejected')) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please approve the connection of the Keplr account to the Chain!'
      } else if (error.message.includes('wrongEthNetwork')) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌ </br></br> Please change metamask network to Binance Mainnet!'
      } else {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Initialization failed! ❌'
      }

      document.querySelector('.geo-db__loader-title').innerHTML +=
        '</br></br> Please refresh the page.'
    }
  }, 1000)
})

document
  .querySelector('.geo-db__form')
  .addEventListener('submit', async function (e) {
    e.preventDefault()

    document.querySelector('.geo-db__form-submit-fancy').disabled = true
    document.querySelector('.geo-db__loader').classList.add('show')
    document.querySelector('.geo-db__loader-title').innerHTML =
      'Sending Transaction! :)'

    if (document.querySelector('.geo-db__form-info.error')) {
      document
        .querySelector('.geo-db__form-info.error')
        .classList.remove('error')
    }

    if (
      document.querySelector('.geo-db__form-info').style.display === 'block'
    ) {
      document.querySelector('.geo-db__form-info').innerHTML = ''
      document.querySelector('.geo-db__form-info').style.display = 'none'
    }

    try {
      // Tokens amount
      const amountToLock = web3.utils.toBN(
        web3.utils.toWei(document.querySelector('#lock_amount').value, 'ether')
      )
      if (amountToLock.gte(balance)) {
        document.querySelector(
          '.geo-db__form-info'
        ).innerHTML = `Insufficient funds!`
        document.querySelector('.geo-db__form-info').classList.add('error')
        throw new Error('Insufficient funds')
      }

      if (!isWhiteListed) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'You are not in whitelist! 🚫'
        await sleep(2000)
        throw new Error('You are not in whitelist! 🚫')
      }
      const allowance = web3.utils.toBN(
        await contractERC20.methods
          .allowance(
            web3.currentProvider.selectedAddress,
            contractPermaLockAddress
          )
          .call()
      )
      let approve = false
      if (allowance.lt(amountToLock)) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Approving...'
        approve = await contractERC20.methods
          .approve(contractPermaLockAddress, amountToLock.toString())
          .send({ from: web3.currentProvider.selectedAddress })
          .on('receipt', function (receipt) {
            if (receipt.status) {
              approve = true
            }
          })
      } else {
        approve = true
      }
      if (approve) {
        document.querySelector('.geo-db__loader-title').innerHTML =
          'Confirm the transaction in MetaMask to proceed with the lock!'
        await contractPermaLock.methods
          .permaLock(amountToLock.toString(), odinAddress)
          .send({ from: web3.currentProvider.selectedAddress })
          .on('transactionHash', function (hash) {
            const txLink = `https://${
              isPro ? '' : 'testnet.'
            }bscscan.com/tx/${hash}`
            document.querySelector(
              '.geo-db__loader-title'
            ).innerHTML = `Transaction was sent, your TxHash: ${hash},<br/> Please wait till tx finish!`
            document.querySelector(
              '.geo-db__form-info'
            ).innerHTML = `Transaction success, check your TxHash: <br/> <a href="${txLink}" target="_blank" rel="noopener noreferrer">${txLink}</a>`
          })
          .on('error', function (error, receipt) {
            if (error.code === 4001) {
              document.querySelector('.geo-db__form-info').innerHTML =
                '<h2 class="error">User denied transaction signature</h2>'
            } else {
              document.querySelector('.geo-db__form-info').innerHTML =
                '<h2 class="error">Something has gone wrong</h2>'
            }
            document.querySelector('.geo-db__form-info').classList.add('error')
          })
      }
    } catch (error) {
      console.error(error)
    }
    document.querySelector('.geo-db__form-submit-fancy').disabled = false
    document.querySelector('.geo-db__loader').classList.remove('show')
    if (document.querySelector('.geo-db__form-info').childNodes.length > 0) {
      document.querySelector('.geo-db__form-info').style.display = 'block'
    }
    document.querySelector('.geo-db__loader').classList.remove('show')
    await updateLockBalances()
  })
