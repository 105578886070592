// source: tendermint/types/types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var gogoproto_gogo_pb = require('../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var tendermint_crypto_proof_pb = require('../../tendermint/crypto/proof_pb.js');
goog.object.extend(proto, tendermint_crypto_proof_pb);
var tendermint_version_types_pb = require('../../tendermint/version/types_pb.js');
goog.object.extend(proto, tendermint_version_types_pb);
var tendermint_types_validator_pb = require('../../tendermint/types/validator_pb.js');
goog.object.extend(proto, tendermint_types_validator_pb);
goog.exportSymbol('proto.tendermint.types.BlockID', null, global);
goog.exportSymbol('proto.tendermint.types.BlockIDFlag', null, global);
goog.exportSymbol('proto.tendermint.types.BlockMeta', null, global);
goog.exportSymbol('proto.tendermint.types.Commit', null, global);
goog.exportSymbol('proto.tendermint.types.CommitSig', null, global);
goog.exportSymbol('proto.tendermint.types.Data', null, global);
goog.exportSymbol('proto.tendermint.types.Header', null, global);
goog.exportSymbol('proto.tendermint.types.LightBlock', null, global);
goog.exportSymbol('proto.tendermint.types.Part', null, global);
goog.exportSymbol('proto.tendermint.types.PartSetHeader', null, global);
goog.exportSymbol('proto.tendermint.types.Proposal', null, global);
goog.exportSymbol('proto.tendermint.types.SignedHeader', null, global);
goog.exportSymbol('proto.tendermint.types.SignedMsgType', null, global);
goog.exportSymbol('proto.tendermint.types.TxProof', null, global);
goog.exportSymbol('proto.tendermint.types.Vote', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.PartSetHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.PartSetHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.PartSetHeader.displayName = 'proto.tendermint.types.PartSetHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.Part = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.Part, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.Part.displayName = 'proto.tendermint.types.Part';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.BlockID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.BlockID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.BlockID.displayName = 'proto.tendermint.types.BlockID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.Header = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.Header, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.Header.displayName = 'proto.tendermint.types.Header';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tendermint.types.Data.repeatedFields_, null);
};
goog.inherits(proto.tendermint.types.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.Data.displayName = 'proto.tendermint.types.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.Vote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.Vote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.Vote.displayName = 'proto.tendermint.types.Vote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.Commit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tendermint.types.Commit.repeatedFields_, null);
};
goog.inherits(proto.tendermint.types.Commit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.Commit.displayName = 'proto.tendermint.types.Commit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.CommitSig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.CommitSig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.CommitSig.displayName = 'proto.tendermint.types.CommitSig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.Proposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.Proposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.Proposal.displayName = 'proto.tendermint.types.Proposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.SignedHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.SignedHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.SignedHeader.displayName = 'proto.tendermint.types.SignedHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.LightBlock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.LightBlock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.LightBlock.displayName = 'proto.tendermint.types.LightBlock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.BlockMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.BlockMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.BlockMeta.displayName = 'proto.tendermint.types.BlockMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tendermint.types.TxProof = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tendermint.types.TxProof, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tendermint.types.TxProof.displayName = 'proto.tendermint.types.TxProof';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.PartSetHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.PartSetHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.PartSetHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.PartSetHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hash: msg.getHash_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.PartSetHeader}
 */
proto.tendermint.types.PartSetHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.PartSetHeader;
  return proto.tendermint.types.PartSetHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.PartSetHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.PartSetHeader}
 */
proto.tendermint.types.PartSetHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.PartSetHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.PartSetHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.PartSetHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.PartSetHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint32 total = 1;
 * @return {number}
 */
proto.tendermint.types.PartSetHeader.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.PartSetHeader} returns this
 */
proto.tendermint.types.PartSetHeader.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes hash = 2;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.PartSetHeader.prototype.getHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes hash = 2;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
proto.tendermint.types.PartSetHeader.prototype.getHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHash()));
};


/**
 * optional bytes hash = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.PartSetHeader.prototype.getHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.PartSetHeader} returns this
 */
proto.tendermint.types.PartSetHeader.prototype.setHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.Part.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.Part.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.Part} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Part.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bytes: msg.getBytes_asB64(),
    proof: (f = msg.getProof()) && tendermint_crypto_proof_pb.Proof.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.Part}
 */
proto.tendermint.types.Part.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.Part;
  return proto.tendermint.types.Part.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.Part} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.Part}
 */
proto.tendermint.types.Part.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBytes(value);
      break;
    case 3:
      var value = new tendermint_crypto_proof_pb.Proof;
      reader.readMessage(value,tendermint_crypto_proof_pb.Proof.deserializeBinaryFromReader);
      msg.setProof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.Part.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.Part.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.Part} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Part.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getProof();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      tendermint_crypto_proof_pb.Proof.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 index = 1;
 * @return {number}
 */
proto.tendermint.types.Part.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Part} returns this
 */
proto.tendermint.types.Part.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes bytes = 2;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Part.prototype.getBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes bytes = 2;
 * This is a type-conversion wrapper around `getBytes()`
 * @return {string}
 */
proto.tendermint.types.Part.prototype.getBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytes()));
};


/**
 * optional bytes bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytes()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Part.prototype.getBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Part} returns this
 */
proto.tendermint.types.Part.prototype.setBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional tendermint.crypto.Proof proof = 3;
 * @return {?proto.tendermint.crypto.Proof}
 */
proto.tendermint.types.Part.prototype.getProof = function() {
  return /** @type{?proto.tendermint.crypto.Proof} */ (
    jspb.Message.getWrapperField(this, tendermint_crypto_proof_pb.Proof, 3));
};


/**
 * @param {?proto.tendermint.crypto.Proof|undefined} value
 * @return {!proto.tendermint.types.Part} returns this
*/
proto.tendermint.types.Part.prototype.setProof = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Part} returns this
 */
proto.tendermint.types.Part.prototype.clearProof = function() {
  return this.setProof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Part.prototype.hasProof = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.BlockID.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.BlockID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.BlockID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.BlockID.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: msg.getHash_asB64(),
    partSetHeader: (f = msg.getPartSetHeader()) && proto.tendermint.types.PartSetHeader.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.BlockID}
 */
proto.tendermint.types.BlockID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.BlockID;
  return proto.tendermint.types.BlockID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.BlockID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.BlockID}
 */
proto.tendermint.types.BlockID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHash(value);
      break;
    case 2:
      var value = new proto.tendermint.types.PartSetHeader;
      reader.readMessage(value,proto.tendermint.types.PartSetHeader.deserializeBinaryFromReader);
      msg.setPartSetHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.BlockID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.BlockID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.BlockID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.BlockID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getPartSetHeader();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.tendermint.types.PartSetHeader.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes hash = 1;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.BlockID.prototype.getHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes hash = 1;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
proto.tendermint.types.BlockID.prototype.getHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHash()));
};


/**
 * optional bytes hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.BlockID.prototype.getHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.BlockID} returns this
 */
proto.tendermint.types.BlockID.prototype.setHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional PartSetHeader part_set_header = 2;
 * @return {?proto.tendermint.types.PartSetHeader}
 */
proto.tendermint.types.BlockID.prototype.getPartSetHeader = function() {
  return /** @type{?proto.tendermint.types.PartSetHeader} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.PartSetHeader, 2));
};


/**
 * @param {?proto.tendermint.types.PartSetHeader|undefined} value
 * @return {!proto.tendermint.types.BlockID} returns this
*/
proto.tendermint.types.BlockID.prototype.setPartSetHeader = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.BlockID} returns this
 */
proto.tendermint.types.BlockID.prototype.clearPartSetHeader = function() {
  return this.setPartSetHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.BlockID.prototype.hasPartSetHeader = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.Header.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.Header.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.Header} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Header.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: (f = msg.getVersion()) && tendermint_version_types_pb.Consensus.toObject(includeInstance, f),
    chainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    height: jspb.Message.getFieldWithDefault(msg, 3, 0),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastBlockId: (f = msg.getLastBlockId()) && proto.tendermint.types.BlockID.toObject(includeInstance, f),
    lastCommitHash: msg.getLastCommitHash_asB64(),
    dataHash: msg.getDataHash_asB64(),
    validatorsHash: msg.getValidatorsHash_asB64(),
    nextValidatorsHash: msg.getNextValidatorsHash_asB64(),
    consensusHash: msg.getConsensusHash_asB64(),
    appHash: msg.getAppHash_asB64(),
    lastResultsHash: msg.getLastResultsHash_asB64(),
    evidenceHash: msg.getEvidenceHash_asB64(),
    proposerAddress: msg.getProposerAddress_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.Header}
 */
proto.tendermint.types.Header.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.Header;
  return proto.tendermint.types.Header.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.Header} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.Header}
 */
proto.tendermint.types.Header.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new tendermint_version_types_pb.Consensus;
      reader.readMessage(value,tendermint_version_types_pb.Consensus.deserializeBinaryFromReader);
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeight(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 5:
      var value = new proto.tendermint.types.BlockID;
      reader.readMessage(value,proto.tendermint.types.BlockID.deserializeBinaryFromReader);
      msg.setLastBlockId(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastCommitHash(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDataHash(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValidatorsHash(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNextValidatorsHash(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setConsensusHash(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAppHash(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastResultsHash(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEvidenceHash(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setProposerAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.Header.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.Header} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Header.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      tendermint_version_types_pb.Consensus.serializeBinaryToWriter
    );
  }
  f = message.getChainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastBlockId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.tendermint.types.BlockID.serializeBinaryToWriter
    );
  }
  f = message.getLastCommitHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getDataHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getValidatorsHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getNextValidatorsHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = message.getConsensusHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
  f = message.getAppHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getLastResultsHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getEvidenceHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getProposerAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
};


/**
 * optional tendermint.version.Consensus version = 1;
 * @return {?proto.tendermint.version.Consensus}
 */
proto.tendermint.types.Header.prototype.getVersion = function() {
  return /** @type{?proto.tendermint.version.Consensus} */ (
    jspb.Message.getWrapperField(this, tendermint_version_types_pb.Consensus, 1));
};


/**
 * @param {?proto.tendermint.version.Consensus|undefined} value
 * @return {!proto.tendermint.types.Header} returns this
*/
proto.tendermint.types.Header.prototype.setVersion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.clearVersion = function() {
  return this.setVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Header.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string chain_id = 2;
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 height = 3;
 * @return {number}
 */
proto.tendermint.types.Header.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.tendermint.types.Header.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.tendermint.types.Header} returns this
*/
proto.tendermint.types.Header.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Header.prototype.hasTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BlockID last_block_id = 5;
 * @return {?proto.tendermint.types.BlockID}
 */
proto.tendermint.types.Header.prototype.getLastBlockId = function() {
  return /** @type{?proto.tendermint.types.BlockID} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.BlockID, 5));
};


/**
 * @param {?proto.tendermint.types.BlockID|undefined} value
 * @return {!proto.tendermint.types.Header} returns this
*/
proto.tendermint.types.Header.prototype.setLastBlockId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.clearLastBlockId = function() {
  return this.setLastBlockId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Header.prototype.hasLastBlockId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes last_commit_hash = 6;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getLastCommitHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes last_commit_hash = 6;
 * This is a type-conversion wrapper around `getLastCommitHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getLastCommitHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastCommitHash()));
};


/**
 * optional bytes last_commit_hash = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastCommitHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getLastCommitHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastCommitHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setLastCommitHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional bytes data_hash = 7;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getDataHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes data_hash = 7;
 * This is a type-conversion wrapper around `getDataHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getDataHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDataHash()));
};


/**
 * optional bytes data_hash = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDataHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getDataHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDataHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setDataHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};


/**
 * optional bytes validators_hash = 8;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getValidatorsHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes validators_hash = 8;
 * This is a type-conversion wrapper around `getValidatorsHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getValidatorsHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValidatorsHash()));
};


/**
 * optional bytes validators_hash = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValidatorsHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getValidatorsHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValidatorsHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setValidatorsHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional bytes next_validators_hash = 9;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getNextValidatorsHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes next_validators_hash = 9;
 * This is a type-conversion wrapper around `getNextValidatorsHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getNextValidatorsHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNextValidatorsHash()));
};


/**
 * optional bytes next_validators_hash = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNextValidatorsHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getNextValidatorsHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNextValidatorsHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setNextValidatorsHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 9, value);
};


/**
 * optional bytes consensus_hash = 10;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getConsensusHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes consensus_hash = 10;
 * This is a type-conversion wrapper around `getConsensusHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getConsensusHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getConsensusHash()));
};


/**
 * optional bytes consensus_hash = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getConsensusHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getConsensusHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getConsensusHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setConsensusHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};


/**
 * optional bytes app_hash = 11;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getAppHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes app_hash = 11;
 * This is a type-conversion wrapper around `getAppHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getAppHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAppHash()));
};


/**
 * optional bytes app_hash = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAppHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getAppHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAppHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setAppHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional bytes last_results_hash = 12;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getLastResultsHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes last_results_hash = 12;
 * This is a type-conversion wrapper around `getLastResultsHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getLastResultsHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastResultsHash()));
};


/**
 * optional bytes last_results_hash = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastResultsHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getLastResultsHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastResultsHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setLastResultsHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 12, value);
};


/**
 * optional bytes evidence_hash = 13;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getEvidenceHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes evidence_hash = 13;
 * This is a type-conversion wrapper around `getEvidenceHash()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getEvidenceHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEvidenceHash()));
};


/**
 * optional bytes evidence_hash = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEvidenceHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getEvidenceHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEvidenceHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setEvidenceHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional bytes proposer_address = 14;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Header.prototype.getProposerAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes proposer_address = 14;
 * This is a type-conversion wrapper around `getProposerAddress()`
 * @return {string}
 */
proto.tendermint.types.Header.prototype.getProposerAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getProposerAddress()));
};


/**
 * optional bytes proposer_address = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getProposerAddress()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Header.prototype.getProposerAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getProposerAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Header} returns this
 */
proto.tendermint.types.Header.prototype.setProposerAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tendermint.types.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    txsList: msg.getTxsList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.Data}
 */
proto.tendermint.types.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.Data;
  return proto.tendermint.types.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.Data}
 */
proto.tendermint.types.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addTxs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
};


/**
 * repeated bytes txs = 1;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.tendermint.types.Data.prototype.getTxsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * repeated bytes txs = 1;
 * This is a type-conversion wrapper around `getTxsList()`
 * @return {!Array<string>}
 */
proto.tendermint.types.Data.prototype.getTxsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getTxsList()));
};


/**
 * repeated bytes txs = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTxsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.tendermint.types.Data.prototype.getTxsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getTxsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.tendermint.types.Data} returns this
 */
proto.tendermint.types.Data.prototype.setTxsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.tendermint.types.Data} returns this
 */
proto.tendermint.types.Data.prototype.addTxs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tendermint.types.Data} returns this
 */
proto.tendermint.types.Data.prototype.clearTxsList = function() {
  return this.setTxsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.Vote.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.Vote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.Vote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Vote.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    round: jspb.Message.getFieldWithDefault(msg, 3, 0),
    blockId: (f = msg.getBlockId()) && proto.tendermint.types.BlockID.toObject(includeInstance, f),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validatorAddress: msg.getValidatorAddress_asB64(),
    validatorIndex: jspb.Message.getFieldWithDefault(msg, 7, 0),
    signature: msg.getSignature_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.Vote}
 */
proto.tendermint.types.Vote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.Vote;
  return proto.tendermint.types.Vote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.Vote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.Vote}
 */
proto.tendermint.types.Vote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.tendermint.types.SignedMsgType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRound(value);
      break;
    case 4:
      var value = new proto.tendermint.types.BlockID;
      reader.readMessage(value,proto.tendermint.types.BlockID.deserializeBinaryFromReader);
      msg.setBlockId(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValidatorAddress(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidatorIndex(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.Vote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.Vote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.Vote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Vote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRound();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBlockId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.tendermint.types.BlockID.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidatorAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getValidatorIndex();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
};


/**
 * optional SignedMsgType type = 1;
 * @return {!proto.tendermint.types.SignedMsgType}
 */
proto.tendermint.types.Vote.prototype.getType = function() {
  return /** @type {!proto.tendermint.types.SignedMsgType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.tendermint.types.SignedMsgType} value
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 height = 2;
 * @return {number}
 */
proto.tendermint.types.Vote.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 round = 3;
 * @return {number}
 */
proto.tendermint.types.Vote.prototype.getRound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.setRound = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional BlockID block_id = 4;
 * @return {?proto.tendermint.types.BlockID}
 */
proto.tendermint.types.Vote.prototype.getBlockId = function() {
  return /** @type{?proto.tendermint.types.BlockID} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.BlockID, 4));
};


/**
 * @param {?proto.tendermint.types.BlockID|undefined} value
 * @return {!proto.tendermint.types.Vote} returns this
*/
proto.tendermint.types.Vote.prototype.setBlockId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.clearBlockId = function() {
  return this.setBlockId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Vote.prototype.hasBlockId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.tendermint.types.Vote.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.tendermint.types.Vote} returns this
*/
proto.tendermint.types.Vote.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Vote.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes validator_address = 6;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Vote.prototype.getValidatorAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes validator_address = 6;
 * This is a type-conversion wrapper around `getValidatorAddress()`
 * @return {string}
 */
proto.tendermint.types.Vote.prototype.getValidatorAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValidatorAddress()));
};


/**
 * optional bytes validator_address = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValidatorAddress()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Vote.prototype.getValidatorAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValidatorAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.setValidatorAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};


/**
 * optional int32 validator_index = 7;
 * @return {number}
 */
proto.tendermint.types.Vote.prototype.getValidatorIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.setValidatorIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bytes signature = 8;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Vote.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes signature = 8;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.tendermint.types.Vote.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Vote.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Vote} returns this
 */
proto.tendermint.types.Vote.prototype.setSignature = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tendermint.types.Commit.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.Commit.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.Commit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.Commit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Commit.toObject = function(includeInstance, msg) {
  var f, obj = {
    height: jspb.Message.getFieldWithDefault(msg, 1, 0),
    round: jspb.Message.getFieldWithDefault(msg, 2, 0),
    blockId: (f = msg.getBlockId()) && proto.tendermint.types.BlockID.toObject(includeInstance, f),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    proto.tendermint.types.CommitSig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.Commit}
 */
proto.tendermint.types.Commit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.Commit;
  return proto.tendermint.types.Commit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.Commit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.Commit}
 */
proto.tendermint.types.Commit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeight(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRound(value);
      break;
    case 3:
      var value = new proto.tendermint.types.BlockID;
      reader.readMessage(value,proto.tendermint.types.BlockID.deserializeBinaryFromReader);
      msg.setBlockId(value);
      break;
    case 4:
      var value = new proto.tendermint.types.CommitSig;
      reader.readMessage(value,proto.tendermint.types.CommitSig.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.Commit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.Commit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.Commit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Commit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRound();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBlockId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.tendermint.types.BlockID.serializeBinaryToWriter
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.tendermint.types.CommitSig.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 height = 1;
 * @return {number}
 */
proto.tendermint.types.Commit.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Commit} returns this
 */
proto.tendermint.types.Commit.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 round = 2;
 * @return {number}
 */
proto.tendermint.types.Commit.prototype.getRound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Commit} returns this
 */
proto.tendermint.types.Commit.prototype.setRound = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional BlockID block_id = 3;
 * @return {?proto.tendermint.types.BlockID}
 */
proto.tendermint.types.Commit.prototype.getBlockId = function() {
  return /** @type{?proto.tendermint.types.BlockID} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.BlockID, 3));
};


/**
 * @param {?proto.tendermint.types.BlockID|undefined} value
 * @return {!proto.tendermint.types.Commit} returns this
*/
proto.tendermint.types.Commit.prototype.setBlockId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Commit} returns this
 */
proto.tendermint.types.Commit.prototype.clearBlockId = function() {
  return this.setBlockId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Commit.prototype.hasBlockId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated CommitSig signatures = 4;
 * @return {!Array<!proto.tendermint.types.CommitSig>}
 */
proto.tendermint.types.Commit.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.tendermint.types.CommitSig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tendermint.types.CommitSig, 4));
};


/**
 * @param {!Array<!proto.tendermint.types.CommitSig>} value
 * @return {!proto.tendermint.types.Commit} returns this
*/
proto.tendermint.types.Commit.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.tendermint.types.CommitSig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tendermint.types.CommitSig}
 */
proto.tendermint.types.Commit.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.tendermint.types.CommitSig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tendermint.types.Commit} returns this
 */
proto.tendermint.types.Commit.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.CommitSig.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.CommitSig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.CommitSig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.CommitSig.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockIdFlag: jspb.Message.getFieldWithDefault(msg, 1, 0),
    validatorAddress: msg.getValidatorAddress_asB64(),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    signature: msg.getSignature_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.CommitSig}
 */
proto.tendermint.types.CommitSig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.CommitSig;
  return proto.tendermint.types.CommitSig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.CommitSig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.CommitSig}
 */
proto.tendermint.types.CommitSig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.tendermint.types.BlockIDFlag} */ (reader.readEnum());
      msg.setBlockIdFlag(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValidatorAddress(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.CommitSig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.CommitSig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.CommitSig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.CommitSig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockIdFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValidatorAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional BlockIDFlag block_id_flag = 1;
 * @return {!proto.tendermint.types.BlockIDFlag}
 */
proto.tendermint.types.CommitSig.prototype.getBlockIdFlag = function() {
  return /** @type {!proto.tendermint.types.BlockIDFlag} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.tendermint.types.BlockIDFlag} value
 * @return {!proto.tendermint.types.CommitSig} returns this
 */
proto.tendermint.types.CommitSig.prototype.setBlockIdFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes validator_address = 2;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.CommitSig.prototype.getValidatorAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes validator_address = 2;
 * This is a type-conversion wrapper around `getValidatorAddress()`
 * @return {string}
 */
proto.tendermint.types.CommitSig.prototype.getValidatorAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValidatorAddress()));
};


/**
 * optional bytes validator_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValidatorAddress()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.CommitSig.prototype.getValidatorAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValidatorAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.CommitSig} returns this
 */
proto.tendermint.types.CommitSig.prototype.setValidatorAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.tendermint.types.CommitSig.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.tendermint.types.CommitSig} returns this
*/
proto.tendermint.types.CommitSig.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.CommitSig} returns this
 */
proto.tendermint.types.CommitSig.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.CommitSig.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes signature = 4;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.CommitSig.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes signature = 4;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.tendermint.types.CommitSig.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.CommitSig.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.CommitSig} returns this
 */
proto.tendermint.types.CommitSig.prototype.setSignature = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.Proposal.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.Proposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.Proposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Proposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    round: jspb.Message.getFieldWithDefault(msg, 3, 0),
    polRound: jspb.Message.getFieldWithDefault(msg, 4, 0),
    blockId: (f = msg.getBlockId()) && proto.tendermint.types.BlockID.toObject(includeInstance, f),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    signature: msg.getSignature_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.Proposal}
 */
proto.tendermint.types.Proposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.Proposal;
  return proto.tendermint.types.Proposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.Proposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.Proposal}
 */
proto.tendermint.types.Proposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.tendermint.types.SignedMsgType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRound(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPolRound(value);
      break;
    case 5:
      var value = new proto.tendermint.types.BlockID;
      reader.readMessage(value,proto.tendermint.types.BlockID.deserializeBinaryFromReader);
      msg.setBlockId(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.Proposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.Proposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.Proposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.Proposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRound();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPolRound();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBlockId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.tendermint.types.BlockID.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      7,
      f
    );
  }
};


/**
 * optional SignedMsgType type = 1;
 * @return {!proto.tendermint.types.SignedMsgType}
 */
proto.tendermint.types.Proposal.prototype.getType = function() {
  return /** @type {!proto.tendermint.types.SignedMsgType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.tendermint.types.SignedMsgType} value
 * @return {!proto.tendermint.types.Proposal} returns this
 */
proto.tendermint.types.Proposal.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 height = 2;
 * @return {number}
 */
proto.tendermint.types.Proposal.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Proposal} returns this
 */
proto.tendermint.types.Proposal.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 round = 3;
 * @return {number}
 */
proto.tendermint.types.Proposal.prototype.getRound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Proposal} returns this
 */
proto.tendermint.types.Proposal.prototype.setRound = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 pol_round = 4;
 * @return {number}
 */
proto.tendermint.types.Proposal.prototype.getPolRound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.Proposal} returns this
 */
proto.tendermint.types.Proposal.prototype.setPolRound = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional BlockID block_id = 5;
 * @return {?proto.tendermint.types.BlockID}
 */
proto.tendermint.types.Proposal.prototype.getBlockId = function() {
  return /** @type{?proto.tendermint.types.BlockID} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.BlockID, 5));
};


/**
 * @param {?proto.tendermint.types.BlockID|undefined} value
 * @return {!proto.tendermint.types.Proposal} returns this
*/
proto.tendermint.types.Proposal.prototype.setBlockId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Proposal} returns this
 */
proto.tendermint.types.Proposal.prototype.clearBlockId = function() {
  return this.setBlockId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Proposal.prototype.hasBlockId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.tendermint.types.Proposal.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.tendermint.types.Proposal} returns this
*/
proto.tendermint.types.Proposal.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.Proposal} returns this
 */
proto.tendermint.types.Proposal.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.Proposal.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes signature = 7;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.Proposal.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes signature = 7;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.tendermint.types.Proposal.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.Proposal.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.Proposal} returns this
 */
proto.tendermint.types.Proposal.prototype.setSignature = function(value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.SignedHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.SignedHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.SignedHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.SignedHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.tendermint.types.Header.toObject(includeInstance, f),
    commit: (f = msg.getCommit()) && proto.tendermint.types.Commit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.SignedHeader}
 */
proto.tendermint.types.SignedHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.SignedHeader;
  return proto.tendermint.types.SignedHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.SignedHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.SignedHeader}
 */
proto.tendermint.types.SignedHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tendermint.types.Header;
      reader.readMessage(value,proto.tendermint.types.Header.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto.tendermint.types.Commit;
      reader.readMessage(value,proto.tendermint.types.Commit.deserializeBinaryFromReader);
      msg.setCommit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.SignedHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.SignedHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.SignedHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.SignedHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tendermint.types.Header.serializeBinaryToWriter
    );
  }
  f = message.getCommit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.tendermint.types.Commit.serializeBinaryToWriter
    );
  }
};


/**
 * optional Header header = 1;
 * @return {?proto.tendermint.types.Header}
 */
proto.tendermint.types.SignedHeader.prototype.getHeader = function() {
  return /** @type{?proto.tendermint.types.Header} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.Header, 1));
};


/**
 * @param {?proto.tendermint.types.Header|undefined} value
 * @return {!proto.tendermint.types.SignedHeader} returns this
*/
proto.tendermint.types.SignedHeader.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.SignedHeader} returns this
 */
proto.tendermint.types.SignedHeader.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.SignedHeader.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Commit commit = 2;
 * @return {?proto.tendermint.types.Commit}
 */
proto.tendermint.types.SignedHeader.prototype.getCommit = function() {
  return /** @type{?proto.tendermint.types.Commit} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.Commit, 2));
};


/**
 * @param {?proto.tendermint.types.Commit|undefined} value
 * @return {!proto.tendermint.types.SignedHeader} returns this
*/
proto.tendermint.types.SignedHeader.prototype.setCommit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.SignedHeader} returns this
 */
proto.tendermint.types.SignedHeader.prototype.clearCommit = function() {
  return this.setCommit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.SignedHeader.prototype.hasCommit = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.LightBlock.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.LightBlock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.LightBlock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.LightBlock.toObject = function(includeInstance, msg) {
  var f, obj = {
    signedHeader: (f = msg.getSignedHeader()) && proto.tendermint.types.SignedHeader.toObject(includeInstance, f),
    validatorSet: (f = msg.getValidatorSet()) && tendermint_types_validator_pb.ValidatorSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.LightBlock}
 */
proto.tendermint.types.LightBlock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.LightBlock;
  return proto.tendermint.types.LightBlock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.LightBlock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.LightBlock}
 */
proto.tendermint.types.LightBlock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tendermint.types.SignedHeader;
      reader.readMessage(value,proto.tendermint.types.SignedHeader.deserializeBinaryFromReader);
      msg.setSignedHeader(value);
      break;
    case 2:
      var value = new tendermint_types_validator_pb.ValidatorSet;
      reader.readMessage(value,tendermint_types_validator_pb.ValidatorSet.deserializeBinaryFromReader);
      msg.setValidatorSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.LightBlock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.LightBlock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.LightBlock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.LightBlock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignedHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tendermint.types.SignedHeader.serializeBinaryToWriter
    );
  }
  f = message.getValidatorSet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      tendermint_types_validator_pb.ValidatorSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional SignedHeader signed_header = 1;
 * @return {?proto.tendermint.types.SignedHeader}
 */
proto.tendermint.types.LightBlock.prototype.getSignedHeader = function() {
  return /** @type{?proto.tendermint.types.SignedHeader} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.SignedHeader, 1));
};


/**
 * @param {?proto.tendermint.types.SignedHeader|undefined} value
 * @return {!proto.tendermint.types.LightBlock} returns this
*/
proto.tendermint.types.LightBlock.prototype.setSignedHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.LightBlock} returns this
 */
proto.tendermint.types.LightBlock.prototype.clearSignedHeader = function() {
  return this.setSignedHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.LightBlock.prototype.hasSignedHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ValidatorSet validator_set = 2;
 * @return {?proto.tendermint.types.ValidatorSet}
 */
proto.tendermint.types.LightBlock.prototype.getValidatorSet = function() {
  return /** @type{?proto.tendermint.types.ValidatorSet} */ (
    jspb.Message.getWrapperField(this, tendermint_types_validator_pb.ValidatorSet, 2));
};


/**
 * @param {?proto.tendermint.types.ValidatorSet|undefined} value
 * @return {!proto.tendermint.types.LightBlock} returns this
*/
proto.tendermint.types.LightBlock.prototype.setValidatorSet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.LightBlock} returns this
 */
proto.tendermint.types.LightBlock.prototype.clearValidatorSet = function() {
  return this.setValidatorSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.LightBlock.prototype.hasValidatorSet = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.BlockMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.BlockMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.BlockMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.BlockMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockId: (f = msg.getBlockId()) && proto.tendermint.types.BlockID.toObject(includeInstance, f),
    blockSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    header: (f = msg.getHeader()) && proto.tendermint.types.Header.toObject(includeInstance, f),
    numTxs: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.BlockMeta}
 */
proto.tendermint.types.BlockMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.BlockMeta;
  return proto.tendermint.types.BlockMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.BlockMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.BlockMeta}
 */
proto.tendermint.types.BlockMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tendermint.types.BlockID;
      reader.readMessage(value,proto.tendermint.types.BlockID.deserializeBinaryFromReader);
      msg.setBlockId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBlockSize(value);
      break;
    case 3:
      var value = new proto.tendermint.types.Header;
      reader.readMessage(value,proto.tendermint.types.Header.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumTxs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.BlockMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.BlockMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.BlockMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.BlockMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tendermint.types.BlockID.serializeBinaryToWriter
    );
  }
  f = message.getBlockSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.tendermint.types.Header.serializeBinaryToWriter
    );
  }
  f = message.getNumTxs();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional BlockID block_id = 1;
 * @return {?proto.tendermint.types.BlockID}
 */
proto.tendermint.types.BlockMeta.prototype.getBlockId = function() {
  return /** @type{?proto.tendermint.types.BlockID} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.BlockID, 1));
};


/**
 * @param {?proto.tendermint.types.BlockID|undefined} value
 * @return {!proto.tendermint.types.BlockMeta} returns this
*/
proto.tendermint.types.BlockMeta.prototype.setBlockId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.BlockMeta} returns this
 */
proto.tendermint.types.BlockMeta.prototype.clearBlockId = function() {
  return this.setBlockId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.BlockMeta.prototype.hasBlockId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 block_size = 2;
 * @return {number}
 */
proto.tendermint.types.BlockMeta.prototype.getBlockSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.BlockMeta} returns this
 */
proto.tendermint.types.BlockMeta.prototype.setBlockSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Header header = 3;
 * @return {?proto.tendermint.types.Header}
 */
proto.tendermint.types.BlockMeta.prototype.getHeader = function() {
  return /** @type{?proto.tendermint.types.Header} */ (
    jspb.Message.getWrapperField(this, proto.tendermint.types.Header, 3));
};


/**
 * @param {?proto.tendermint.types.Header|undefined} value
 * @return {!proto.tendermint.types.BlockMeta} returns this
*/
proto.tendermint.types.BlockMeta.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.BlockMeta} returns this
 */
proto.tendermint.types.BlockMeta.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.BlockMeta.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 num_txs = 4;
 * @return {number}
 */
proto.tendermint.types.BlockMeta.prototype.getNumTxs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tendermint.types.BlockMeta} returns this
 */
proto.tendermint.types.BlockMeta.prototype.setNumTxs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tendermint.types.TxProof.prototype.toObject = function(opt_includeInstance) {
  return proto.tendermint.types.TxProof.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tendermint.types.TxProof} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.TxProof.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootHash: msg.getRootHash_asB64(),
    data: msg.getData_asB64(),
    proof: (f = msg.getProof()) && tendermint_crypto_proof_pb.Proof.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tendermint.types.TxProof}
 */
proto.tendermint.types.TxProof.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tendermint.types.TxProof;
  return proto.tendermint.types.TxProof.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tendermint.types.TxProof} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tendermint.types.TxProof}
 */
proto.tendermint.types.TxProof.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRootHash(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = new tendermint_crypto_proof_pb.Proof;
      reader.readMessage(value,tendermint_crypto_proof_pb.Proof.deserializeBinaryFromReader);
      msg.setProof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tendermint.types.TxProof.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tendermint.types.TxProof.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tendermint.types.TxProof} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tendermint.types.TxProof.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootHash_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getProof();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      tendermint_crypto_proof_pb.Proof.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes root_hash = 1;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.TxProof.prototype.getRootHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes root_hash = 1;
 * This is a type-conversion wrapper around `getRootHash()`
 * @return {string}
 */
proto.tendermint.types.TxProof.prototype.getRootHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRootHash()));
};


/**
 * optional bytes root_hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRootHash()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.TxProof.prototype.getRootHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRootHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.TxProof} returns this
 */
proto.tendermint.types.TxProof.prototype.setRootHash = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.tendermint.types.TxProof.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.tendermint.types.TxProof.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.tendermint.types.TxProof.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.tendermint.types.TxProof} returns this
 */
proto.tendermint.types.TxProof.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional tendermint.crypto.Proof proof = 3;
 * @return {?proto.tendermint.crypto.Proof}
 */
proto.tendermint.types.TxProof.prototype.getProof = function() {
  return /** @type{?proto.tendermint.crypto.Proof} */ (
    jspb.Message.getWrapperField(this, tendermint_crypto_proof_pb.Proof, 3));
};


/**
 * @param {?proto.tendermint.crypto.Proof|undefined} value
 * @return {!proto.tendermint.types.TxProof} returns this
*/
proto.tendermint.types.TxProof.prototype.setProof = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tendermint.types.TxProof} returns this
 */
proto.tendermint.types.TxProof.prototype.clearProof = function() {
  return this.setProof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tendermint.types.TxProof.prototype.hasProof = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.tendermint.types.BlockIDFlag = {
  BLOCK_ID_FLAG_UNKNOWN: 0,
  BLOCK_ID_FLAG_ABSENT: 1,
  BLOCK_ID_FLAG_COMMIT: 2,
  BLOCK_ID_FLAG_NIL: 3
};

/**
 * @enum {number}
 */
proto.tendermint.types.SignedMsgType = {
  SIGNED_MSG_TYPE_UNKNOWN: 0,
  SIGNED_MSG_TYPE_PREVOTE: 1,
  SIGNED_MSG_TYPE_PRECOMMIT: 2,
  SIGNED_MSG_TYPE_PROPOSAL: 32
};

goog.object.extend(exports, proto.tendermint.types);
